/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout'

const tableRouter = {
  path: '/cadastros',
  component: Layout,
  redirect: '/cadastros/usuarios',
  name: 'Cadastros',
  title: 'Cadastros',
  meta: {
    icon: 'stickies',
    roles: ['admin']
  },
  children: [
    {
      path: 'usuarios',
      component: () => import('@/views/crud/usuario'),
      name: 'Usuários',
      title: 'Usuários'
    },
    {
      path: 'perfis',
      component: () => import('@/views/crud/perfil'),
      name: 'Perfis',
      title: 'Perfis'
    },
    {
      path: 'funcionarios',
      component: () => import('@/views/crud/funcionario'),
      name: 'Funcionários',
      title: 'Funcionários'
    },
    // {
    //   name: 'Formulário de Funcionário',
    //   path: '/funcionario/:id?',
    //   component: () => import('@/views/crud/funcionario-form'),
    //   title: 'Funcionários',
    //   hidden: true
    // },
    {
      path: 'terceiros',
      component: () => import('@/views/crud/terceiro'),
      name: 'Terceiros',
      title: 'Terceiros'
    },
    {
      hidden: true,
      path: 'funcionarios/:id_participante/lotacoes',
      component: () => import('@/views/crud/lotacao'),
      name: 'Lotações',
      title: 'Lotações'
    },
    {
      path: 'fluxos',
      component: () => import('@/views/crud/fluxo'),
      name: 'Fluxos',
      title: 'Fluxos'
    },
    {
      hidden: true,
      path: 'fluxos/:id_fluxo/fases',
      component: () => import('@/views/crud/fase'),
      name: 'Fases',
      title: 'Fases'
    },
    {
      hidden: true,
      path: 'fases/:id_fase/operacoes',
      component: () => import('@/views/crud/operacao'),
      name: 'Operações',
      title: 'Operações'
    },
    {
      hidden: true,
      path: 'operacoes/:id_operacao/acoes',
      component: () => import('@/views/crud/acao'),
      name: 'Ações',
      title: 'Ações'
    },
    {
      path: 'unidades-funcionais',
      component: () => import('@/views/crud/unidade-funcional'),
      name: 'Unidades funcionais',
      title: 'Unidades funcionais'
    },
    {
      hidden: true,
      path: 'unidades-funcionais/:id_unidade_funcional/fluxos',
      component: () => import('@/views/crud/unidade-funcional-fluxo'),
      name: 'Unidade Funcional Fluxos',
      title: 'Unidade Funcional Fluxos'
    },
    {
      hidden: true,
      path: 'unidades-funcionais/:id_unidade_funcional/tipos-peca',
      component: () => import('@/views/crud/unidade-tipo-peca'),
      name: 'Unidade Funcional Tipos de Peça',
      title: 'Unidade Funcional Tipos de Peça'
    },
    {
      path: 'tipos-protocolo',
      component: () => import('@/views/crud/tipo-processo'),
      name: 'Tipos de protocolo',
      title: 'Tipos de protocolo'
    },
    {
      hidden: true,
      path: 'tipos-protocolo/:id_tipo_processo/tipos-peca',
      component: () => import('@/views/crud/tipo-processo-tipo-peca'),
      name: 'Tipo Protocolo Tipos de Peça',
      title: 'Tipo Protocolo Tipos de Peça'
    },
    {
      path: 'tipos-peca',
      component: () => import('@/views/crud/tipo-peca'),
      name: 'Tipos de peça',
      title: 'Tipos de peça'
    },
    {
      path: 'estados',
      component: () => import('@/views/crud/estado'),
      name: 'Estados',
      title: 'Estados'
    },
    {
      path: 'cidades',
      component: () => import('@/views/crud/cidade'),
      name: 'Cidades',
      title: 'Cidades'
    },
    {
      path: 'cargos',
      component: () => import('@/views/crud/cargo'),
      name: 'Cargos',
      title: 'Cargos'
    },
    {
      path: 'formacoes',
      component: () => import('@/views/crud/formacao'),
      name: 'Formacoes',
      title: 'Formações'
    }
  ]
}
export default tableRouter
