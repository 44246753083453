var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "navbar" }, [
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        _vm.device !== "mobile"
          ? [
              _c("span", { staticClass: "right-menu-item" }, [
                _vm._v(_vm._s(_vm.usuario)),
              ]),
              _c("error-log", {
                staticClass: "errLog-container right-menu-item hover-effect",
              }),
            ]
          : _vm._e(),
        _c(
          "el-dropdown",
          {
            staticClass: "avatar-container right-menu-item hover-effect",
            attrs: { trigger: "click" },
          },
          [
            _c(
              "div",
              { staticClass: "avatar-wrapper" },
              [
                _c("svg-icon", {
                  staticClass: "user-avatar",
                  attrs: { "icon-class": "person-circle" },
                }),
              ],
              1
            ),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/perfil/index" } },
                  [_c("el-dropdown-item", [_vm._v("Perfil")])],
                  1
                ),
                _c("el-dropdown-item", { attrs: { divided: "" } }, [
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block" },
                      on: { click: _vm.logout },
                    },
                    [_vm._v("Sair")]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }