<template>
  <el-dialog v-loading="loading" title="Assinar" :modal="false" :visible.sync="dialogVisible" width="60%">
    <el-form
      ref="dataForm"
      :model="temp"
      label-position="left"
      label-width="190px"
      :rules="rules"
      style="margin-left: 50px; margin-right: 50px">
      <el-form-item label="Selecione o certificado" prop="tx_assinatura">
        <el-select v-model="temp.tx_assinatura" placeholder="Select" style="width: 80%">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="warning" icon="el-icon-refresh" @click="updateCertificatesList">
          Atualizar certificados
        </el-button>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">Cancelar</el-button>
      <el-button type="primary" @click="assinar()">Confirmar</el-button>
    </div>
  </el-dialog>
</template>

<script>
import LacunaWebPKI from 'web-pki'
import { Message } from 'element-ui'

import { createRule } from '@/utils'
import { assinarPeca, pegarToken } from '@/api/processo-peca'

export default {
  name: 'PecaUpload',
  data() {
    return {
      options: [],
      peca: null,
      loading: false,
      dialogVisible: false,
      temp: {
        tx_assinatura: null,
        token: null,
        signature: null
      },
      rules: {
        tx_assinatura: createRule([{ label: 'Certificado', type: 'required' }])
      },
      pki: null
    }
  },
  methods: {
    handleShow(peca) {
      this.pki = new LacunaWebPKI()
      this.pki.init(this.onReadyPKI)
      this.peca = peca
      this.dialogVisible = true
    },
    onReadyPKI() {
      this.updateCertificatesList()
    },
    updateCertificatesList() {
      this.loading = true
      this.options = []
      const self = this
      this.pki.listCertificates().success(function (certs) {
        self.loading = false
        certs.forEach((element) => {
          self.options.push({
            value: element.thumbprint,
            label: element.subjectName + ' (emitido por ' + element.issuerName + ')'
          })
        })
      })
    },
    assinar() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.loading = true
          pegarToken(this.peca.id, this.temp).then((response) => {
            if (response.status) {
              this.temp.token = response.data.token
              const self = this
              this.pki
                .signWithRestPki({
                  token: response.data.token,
                  thumbprint: self.temp.tx_assinatura
                })
                .success(function (signature) {
                  self.temp.signature = signature
                  assinarPeca(self.peca.id, self.temp).then((response) => {
                    if (response.status) {
                      self.$notify({
                        title: 'Peça assinada',
                        message: 'Sucesso!',
                        type: 'success',
                        duration: 5000
                      })
                      self.dialogVisible = false
                      self.$emit('peca:signed', response.data)
                    } else {
                      self.$notify({
                        title: 'Error',
                        message: response.message
                          ? response.message
                          : 'A peça precisa estar aprovada para ser assinada.',
                        type: 'error',
                        showClose: true,
                        duration: 20000
                      })
                    }
                    self.loading = false
                  })
                })
                .fail(function (errorMsg) {
                  self.$notify({
                    title: 'Error',
                    message: errorMsg.message,
                    type: 'error',
                    showClose: true,
                    duration: 20000
                  })
                  self.loading = false
                })
            } else {
              Message({
                message: response.message,
                type: 'error',
                showClose: true,
                duration: 20000
              })
              this.loading = false
            }
          })
        }
      })
    }
  }
}
</script>
