<template>
  <el-dialog :title="title" :modal="false" :visible.sync="dialogVisible" width="60%">
    <el-form ref="dataForm" :model="temp" label-position="left" label-width="170px" style="margin-left:50px; margin-right: 50px;">

      <el-form-item label="Texto">
        <el-input
          v-model="temp.tx_texto"
          type="textarea"
          :rows="2"
          style="width:80%"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">
        Cancelar
      </el-button>
      <el-button type="primary" @click="save()">
        Confirmar
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { store } from '@/api/nota'

export default {
  name: 'NotaAdd',
  props: {
    processo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      temp: {
        tx_texto: null
      },
      title: '',
      dialogVisible: false
    }
  },
  methods: {
    handleShow() {
      this.title = 'Protocolo ' + this.processo.tx_identificacao + ' - Nova Nota'
      this.dialogVisible = true
      this.resetTemp()
    },
    resetTemp() {
      this.temp = {
        tx_texto: null
      }
    },
    save() {
      store(this.processo.id, this.temp).then(response => {
        if (response.status) {
          this.$notify({
            title: 'Nota adicionada',
            message: 'Sucesso!',
            type: 'success',
            duration: 2000
          })
          this.dialogVisible = false
          this.$emit('nota:added', response.data)
        } else {
          this.$notify({
            title: 'Nota',
            message: response.message,
            type: 'error',
            duration: 2000
          })
        }
      })
    }
  }
}
</script>
