var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        modal: false,
        visible: _vm.editVisible,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.editVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: { "margin-left": "50px", "margin-right": "50px" },
          attrs: {
            model: _vm.temp,
            "label-position": "left",
            "label-width": "170px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Número da peça" } },
            [
              _c("el-input", {
                staticStyle: { width: "80%" },
                model: {
                  value: _vm.temp.nr_numero,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "nr_numero", $$v)
                  },
                  expression: "temp.nr_numero",
                },
              }),
              _vm.filterFormError("nr_numero")
                ? _c("div", { staticClass: "el-form-item__error" }, [
                    _vm._v(" " + _vm._s(_vm.showMsgError("nr_numero")) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.editVisible = false
                },
              },
            },
            [_vm._v(" Cancelar ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v(" Confirmar ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }