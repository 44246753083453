<!-- eslint-disable no-prototype-builtins -->
<template>
  <div class="novo-protocolo">
    <el-dialog v-if="showDialog" v-loading="loading" :title="gerarTitulo()" :visible.sync="showDialog" width="500px" top="28px">
        <el-form v-if="protocolo" ref="novo-protocolo-form" :model="protocolo" label-position="top"  :rules="rules" label-width="150px">
          <el-row>
                <b style="font-size:20px;padding-left:15px">Unidade funcional: </b> {{ protocolo.nome_unidade_funcional }}
                <!-- <el-form-item label="Unidade Funcional" prop="id_unidade_funcional" class="el-form-item-custom">
                 <custom-list
                    field-key="id_unidade_funcional"
                    :field-value.sync="protocolo.id_unidade_funcional"
                    :field-label.sync="protocolo.nm_unidade_funcional"
                    :query-function="findUnidadeFuncional"
                    :filter.sync="tipoProtocoloFiltros"
                    :config="{disabled: protocolo.hasOwnProperty('id')}"
                    @value:updated="handleUnidadeFuncionalUpdated"
                />
                </el-form-item>
                 <el-form-item label="Fluxo" prop="id_fluxo" class="el-form-item-custom">
                 <custom-list
                    field-key="id_fluxo"
                    :field-value.sync="protocolo.id_fluxo"
                    :field-label.sync="protocolo.nome_fluxo"
                    :query-function="findFluxo"
                    :filter.sync="tipoProtocoloFiltros"
                    :config="{disabled: protocolo.hasOwnProperty('id')}"
                    @value:updated="handleModel"
                />
                </el-form-item> -->
                <el-form-item label="Tipo de protocolo" prop="id_tipo_processo" class="el-form-item-custom">
                <custom-list
                    field-key="id_tipo_processo"
                    :field-value.sync="protocolo.id_tipo_processo"
                    :field-label.sync="protocolo.nome_tipo_processo"
                    :query-function="findTipoProcesso"
                    :filter.sync="tipoProtocoloFiltros"
                    :config="{disabled: protocolo.hasOwnProperty('id')}"
                    @value:updated="handleModel"
                />
            </el-form-item>
                <el-form-item label="Descrição" prop="tx_descricao" class="el-form-item-custom">
                <el-input v-model="protocolo.tx_descricao" type="textarea" />
                </el-form-item>
            </el-row>
          <el-row>
            <el-col :span="12">
                <el-form-item label="Sigiloso?" prop="bl_sigiloso" class="el-form-item-custom">
                    <el-switch v-model="protocolo.bl_sigiloso" :active-value="1" :inactive-value="0" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Eletronico?" prop="bl_eletronico" class="el-form-item-custom">
                <el-switch v-model="protocolo.bl_eletronico" :active-value="1" :inactive-value="0" />
                </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="Interessados" prop="ids_interessados" class="el-form-item-custom">
              <custom-list
                field-key="ids_interessados"
                :field-value.sync="protocolo.ids_interessados"
                :field-label.sync="protocolo.nomes_interessados"
                :query-function="findTerceiros"
                :config="{multipleList: true}"
                @value:updated="handleModel"
              />
            </el-form-item>
          </el-row>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn-secondary" @click="showDialog = false">Cancelar</el-button>
        <el-button type="primary" @click="salvarProtocolo">Salvar</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Message } from 'element-ui'

import { store, update } from '@/api/processo'
import { createRule } from '@/utils'

import { find as findTerceiros } from '@/api/terceiro'
import { find as findTipoProcesso } from '@/api/tipo-processo'
import { find as findUnidadeFuncional } from '@/api/unidade-funcional'
import { find as findFluxo } from '@/api/unidade-funcional-fluxo'

import customList from '@/components/CustomList'

export default {
  components: { customList },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      protocolo: null,
      tipoProtocoloFiltros: null,
      findUnidadeFuncional,
      findFluxo,
      findTipoProcesso,
      findTerceiros,
      rules: {
        'tx_descricao': createRule([
          { label: 'Descrição', type: 'required' }
        ]),
        'id_tipo_processo': createRule([
          { label: 'Tipo de protocolo', type: 'required' }
        ]),
        'ids_interessados': createRule([
          { label: 'Interessados', type: 'array' }
        ])
      }
    }
  },
  beforeMount() {
    this.updateComponent()
  },
  methods: {
    gerarTitulo() {
      // eslint-disable-next-line no-prototype-builtins
      return this.protocolo && this.protocolo.hasOwnProperty('id') ? 'Atualizar: Protocolo - ' + this.protocolo.tx_identificacao : 'Novo Protocolo'
    },
    updateComponent() {
      // carregar os nomes
      this.tipoProtocoloFiltros = {
        id_unidade_funcional: this.filters.id_unidade_funcional,
        id_fluxo: this.filters.id_fluxo
      }
      this.protocolo = {
        id_fase: this.filters.id_fase,
        nome_fase: this.filters.nome_fase,
        id_fluxo: this.filters.id_fluxo,
        nome_fluxo: this.filters.nome_fluxo,
        id_unidade_funcional: this.filters.id_unidade_funcional,
        nome_unidade_funcional: this.filters.nome_unidade_funcional,
        id_tipo_processo: null,
        nome_tipo_processo: null,
        tx_descricao: null,
        ids_interessados: [],
        nomes_interessados: null,
        bl_sigiloso: 0,
        bl_eletronico: 1
      }
    },
    show(protocolo = null) {
      this.updateComponent()
      Object.assign(this.protocolo, protocolo)
      this.showDialog = true
    },
    handlePersist(response) {
      this.loading = false
      if (response.status) {
        Message({
          message: 'Protocolo ' + (this.protocolo.id ? 'atualizado' : 'cadastrado') + ': ' + response.data.tx_identificacao,
          type: 'success',
          showClose: true,
          duration: 10000
        })
        this.showDialog = false
        this.$emit('processo:finalizado', response.data)
      } else {
        const msg = response.data.id_fluxo && response.data.id_fluxo === 'app\\rules\\fluxorule' ? 'Não existe fase inicial definida para o fluxo deste tipo de processo' : response.message
        Message({
          message: 'Erro: ' + msg,
          type: 'warning',
          showClose: true,
          duration: 10000
        })
      }
    },
    salvarProtocolo() {
      this.$refs['novo-protocolo-form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.protocolo.id) {
            update(this.protocolo.id, this.protocolo).then(this.handlePersist)
          } else {
            store(this.protocolo).then(this.handlePersist)
          }
        }
      })
    },
    handleUnidadeFuncionalUpdated(value) {
    this.handleModel(value)
    this.findFluxo(value)
  },
    handleModel(obj) {
      this.protocolo[obj.key] = obj.value
    }
  }
}
</script>
<style lang="scss">
  .el-form-item{
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom:16px !important;
  }
</style>
<style lang="scss" scoped>
.el-form-item{
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom:16px !important;
  }
.el-switch.is-checked .el-switch__core {
  border-color: #007bff;
  background-color: #007bff;
}
.el-form--label-top .el-form-item__label {
  padding: 0 0 10px 0;
}
.el-form-item--medium .el-form-item__label {
  line-height: 24px;
}
.el-form--label-top .el-form-item__label {
  padding: 0 ;
}
  .el-form-item__label{
    padding: 0 ;
  }
  .btn-primary:hover{
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }
  .btn-primary{
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .btn-secondary:hover{
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }
</style>

