import { getToken } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import router from './router'
import store from './store'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/cliente', '/ativar-usuario', '/esqueceuSenha'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()

 if (hasToken) {
  if (to.path === '/login') {
    next({ path: '/' })
    NProgress.done()
  } else {
    const hasRoles = store.getters.roles && store.getters.roles.length > 0
    if (hasRoles) {
      next()
    } else {
      try {
        const { roles, menu } = await store.dispatch('user/getInfo')
        roles.menu = menu

        const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
        router.addRoutes(accessRoutes)

        // Verifique se o usuário tem o papel 'externo' e redirecione conforme necessário
        if (roles.includes('externo')) {
          next({ path: '/usuario-externo/protocolo', replace: true })
        } else {
          next({ ...to, replace: true })
        }
      } catch (error) {
        await store.dispatch('user/resetToken')
        Message.error(error || 'Has Error')
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  }
} else {
  if (new RegExp(whiteList.join('|')).test(to.path)) {
    next()
  } else {
    next(`/login?redirect=${to.path}`)
    NProgress.done()
  }
}
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
