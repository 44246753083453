var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: _vm.computedLink },
                },
                [
                  _vm.logo_menor
                    ? _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: {
                          src: _vm.logo_menor,
                          width: "28",
                          height: "16",
                        },
                      })
                    : _vm._e(),
                ]
              )
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: _vm.computedLink },
                },
                [
                  _vm.logo
                    ? _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: { src: _vm.logo },
                      })
                    : _vm._e(),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }