import Vue from 'vue'

export const helpers = {
  getFilledInfo: (item, key, defaultValue = null) => {
    let value = null

    if ((Array.isArray(item) || typeof item === 'object') && item !== null) {
      value = item[key] || null
    } else {
      value = item
    }

    if (value === null || value === '' || !value) {
      if (defaultValue === null) {
        value = !isNaN(value) || typeof value === 'boolean' ? value : null // keep 0 or false
      } else {
        value = defaultValue
      }
    }

    return value
  }
}

/**
* @param {string} file
* @returns {string}
*/
export function getURL(file) {
  return process.env.VUE_APP_BASE_API + file
}

/**
* @param {string} value
* @returns {string}
*/
export function formatLabel(value) {
  value = value.trim().replace('_', ' ').toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function formatJson(gridColumns, gridData) {
  const data = []
  const header = []

  // create headers
  gridColumns.forEach(column => {
    header.push(column.label)
  })

  // format data
  gridData.forEach((item, i) => {
    data[i] = []
    gridColumns.forEach(column => {
      if (column.export) {
        // eslint-disable-next-line no-prototype-builtins
        data[i].push(parseValues(item[column.key], (column.hasOwnProperty('type') ? column.type : null)))
      }
    })
  })

  return { data: data, header: header }
}

export function deepCopy(inObject) {
  let value, key

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  const outObject = Array.isArray(inObject) ? [] : {}

  for (key in inObject) {
    value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopy(value)
  }

  return outObject
}

/**
 * @param {Object} vueContext
 * @param {string} type
 * @param {string} text
 */
export function notify(response, entity = '') {
  const message = response.message ? response.message : (entity + (response.status ? ' executado com sucesso' : ' não executado'))
  Vue.prototype.$notify({
    title: response.status ? 'Sucesso' : 'Erro',
    message: message,
    type: response.status ? 'success' : 'danger',
    duration: response.status ? 5000 : 0
  })
}

/**
* @param {string} value
* @param {string} key
* @returns {string}
*/
export function parseValues(value, type, back = false) {
  switch (type) {
    case 'status':
      if (!back) {
        return (value === 1) ? 'Ativo' : 'Inativo'
      } else {
        return value
      }
    case 'role':
      if (!back) {
        return (value === 'A') ? 'Admin' : 'Usuário'
      } else {
        return value
      }
    case 'tx_status':
      if (!back) {
        return (value === 'EE') ? 'Em Elaboração' : 'Recebidos'
      } else {
        return value
      }
    case 'pecaCategoria':
      if (!back) {
        return (value === 'P') ? 'Parte Processual' : 'Multimídia'
      } else {
        return value
      }
    case 'userType':
      if (!back) {
        return (value === 'F') ? 'Pessoa Física' : 'Pessoa Jurídica'
      } else {
        return value
      }
    case 'cpf':
      return parseCPF(value, back)
    case 'cpf-cnpj':
      return back ? onlyNumbers(value) : value
    case 'yesOrNo':
      if (!back) {
        return (value) ? 'Sim' : 'Não'
      } else {
        return value
      }
    case 'number':
    case 'list':
    case 'currency':
    case 'percent':
    case Array.isArray(value):
      return value
    default:
      return value ? String(value) : value
  }
}

/**
 * @param {string} cpf
 * @returns {string}
 */
export function parseCPF(cpf, back = false) {
  if (!cpf) {
    return ''
  }
  if (!back) {
    cpf = cpf.toString().replace(/(\d{3})(\d)/, '$1.$2')
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2')
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  } else {
    cpf = cpf.match(/\d/g)
    cpf = cpf ? cpf.join('') : ''
  }

  return cpf
}

/**
 * @param {string} stringToParse
 * @returns {number}
 */
export function onlyNumbers(stringToParse) {
  if (!stringToParse) {
    return ''
  }
  stringToParse = stringToParse.match(/\d/g)
  stringToParse = stringToParse ? stringToParse.join('') : ''

  return stringToParse
}

export function formatDate(date, type = null) {
  switch (type) {
    case 'date':
      return (date) ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY') : ''
    case 'date_sql':
      return (date) ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD') : ''
    case 'time':
      return (date) ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('HH:mm:ss') : ''
    case 'serverDate':
      return (date) ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD HH:mm:ss') : ''
    case 'dateBR':
      return (date) ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY HH:mm:ss') : ''
    case 'dateBR2':
      return (date) ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY') : ''
    case 'date_mdy':
      return (date) ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM-DD-YYYY') : ''
    default:
      return (date) ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY HH:mm:ss') : ''
  }
}

/**
* @param {Object} form
* @returns {FormData}
*/
export function parse2FormData(form, formData = new FormData()) {
  for (var x in form) {
    // to make it simple, delete first and add again
    if (formData.has(x)) {
      formData.delete(x)
    }
    if (formData.has(x + '[]')) {
      formData.delete(x + '[]')
    }

    if (Array.isArray(form[x])) {
      form[x].forEach(e => {
        if (e) {
          formData.append(x + '[]', e)
        }
      })
    } else {
      if (form[x]) {
        formData.append(x, form[x])
      }
    }
  }

  return formData
}

/**
 * @param {Object} temp
 * @param {Array} columns
 * @returns {string}
 */
export function parseToShow(temp, columns) {
  for (var x in temp) {
    for (var y in columns) {
      if (x === columns[y].key) {
        if (typeof columns[y].value !== 'undefined' && temp[x] === '') {
          temp[x] = parseValues(columns[y].value, columns[y].type)
        } else {
          temp[x] = parseValues(temp[x], columns[y].type)
        }
      }
    }
  }
  return temp
}

/**
 * @param {Object} temp
 * @param {Array} columns
 * @returns {string}
 */
export function parseToSend(temp, columns) {
  const result = {}

  for (var x in temp) {
    result[x] = temp[x]
    for (var y in columns) {
      if (x === columns[y].key && columns[y].form) {
        result[x] = parseValues(temp[x], columns[y].type, true)
      }
    }
  }
  return result
}
/**
 * @param {Object} temp
 * @param {Array} columns
 * @returns {string}
 */
export function parseFilterToSend(temp, columns) {
  const result = {}

  for (var x in temp) {
    for (var y in columns) {
      if (columns[y].key === x) {
        result[x] = parseValues(temp[x], columns[y].type, true)
      } else {
        result[x] = temp[x]
      }
    }
  }
  return result
}

/**
 * @param {Array} fields
 * @returns {Array}
 */
export function createRule(fields) {
  const rules = []

  for (var x in fields) {
    switch (fields[x].type) {
      case 'required':
        rules.push({ required: true, message: fields[x].label + ' é obrigatório', trigger: 'blur' })
        break
      case 'valid_email':
        rules.push({ type: 'email', message: 'Campo deve conter um e-mail válido' })
        break
      case 'greater':
        rules.push({ type: 'number', min: 1, message: fields[x].label + ' deve ser maior que 0' })
        break
      case 'array':
        rules.push({ type: 'array', required: true, min: 1, message: 'Deve selecionar ao menos 1', trigger: 'blur' })
        break
    }
  }

  return rules
}

export function errorType(type) {
  let message = ''
  switch (type) {
    case 'exists':
      message = 'A opção selecionada deve existir no cadastro'
      break
    case 'digits':
    case 'digitsbetween':
      message = 'Campo possui a quantidade de dígitos incorreta'
      break
    case 'numeric':
      message = 'Campo deve possuir apenas números'
      break
    case 'required':
      message = 'Campo é obrigatório'
      break
    case 'email':
      message = 'Campo deve conter um E-mail válido'
      break
    case 'requiredif':
      message = 'Campo obrigatório para as condições selecionadas'
      break
    case 'unique':
      message = 'Campo deve ser único'
      break
    case 'string':
      message = 'Campo deve ser um texto'
      break
    case 'max':
      message = 'Campo ultrapassou limite máximo'
      break
    case 'in':
      message = 'Opção inválida para este tipo de campo'
      break
    case 'boolean':
      message = 'Deve selecionar sim ou não'
      break
    case 'app\\rules\\faseentradarule':
      message = 'Só pode existir uma fase de entrada para o fluxo.'
      break
    default:
      message = type
      break
  }
  return message
}

/**
 * @param {string} message
 * @param {object} data
 * @returns {string}
 */
export function parseApiError(type, data) {
  let result

  switch (type) {
    case 'validation_error':

      result = []
      for (var x in data) {
        let new_message = ''
        switch (data[x]) {
          case 'exists':
            new_message = 'A opção selecionada deve existir no cadastro'
            break
          case 'digits':
          case 'digitsbetween':
            new_message = 'Campo possui a quantidade de dígitos incorreta'
            break
          case 'numeric':
            new_message = 'Campo deve possuir apenas números'
            break
          case 'required':
            new_message = 'Campo é obrigatório'
            break
          case 'email':
            new_message = 'Campo deve conter um E-mail válido'
            break
          case 'requiredif':
            new_message = 'Campo obrigatório para as condições selecionadas'
            break
          case 'unique':
            new_message = 'Campo deve ser único'
            break
          case 'string':
            new_message = 'Campo deve ser um texto'
            break
          case 'max':
            new_message = 'Campo ultrapassou limite máximo'
            break
          case 'in':
            new_message = 'Opção inválida para este tipo de campo'
            break
          case 'boolean':
            new_message = 'Deve selecionar sim ou não'
            break
          case 'app\\rules\\faseentradarule':
            new_message = 'Só pode existir uma fase de entrada para o fluxo.'
            break
          case 'integer':
            new_message = 'Apenas números'
            break
          case 'size':
            new_message = 'Tamnho inválido'
            break
          case 'more100%':
            new_message = 'A soma é maior que 100%'
            break
          default:
            new_message = data[x]
            break
        }
        result.push({ field: x, msg: new_message })
      }
      break

    case 'database':
      switch (data) {
        case 'database_delete_1451':
          result = 'There are other records related to this item'
          break
        case 'no_privileges':
          result = 'You are not allowed to delete this record'
          break
        default:
          result = data
      }
      break
  }

  return result
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}
