<template>
	<div class="container">
		<header class="logo" style="height: 73.05px;">
			<div class="logo1">
				<a href="/" ><img :src="`${logo}`"></a>
				<div class="logo_2">
					<p>Não tem uma conta?</p>
					<el-button id="buttonCadastrar"><a  class="a-titulo" @click="cadastrarCliente">Cadastre-se</a>
					</el-button>
				</div>
			</div>
		</header>
		<app-content />
	</div>
  </template>
<script>
import { AppContent} from './components'
  export default {
    name: 'Layout2',
    components: {
      AppContent
    },
		data() {
			return {
				logo: null
			}
		},
		mounted() {
			var images = require.context('@/assets/', false, /\.png$/)
			this.logo = images(`./logo-celerdoc-p.png`)
		},
		methods: {
			cadastrarCliente() {
				this.$router.push({ path: '/cliente' })
			}
		}
  }
  </script>
<style lang="scss" scoped>
@import url('@/assets/fonts/poppins/poppins.css');
@import url('@/assets/fonts/iconic/css/material-design-iconic-font.css');

@font-face {
  font-family: Poppins-Regular;
  src: url('@/assets/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: Poppins-Medium;
  src: url('@/assets/fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: Poppins-Bold;
  src: url('@/assets/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url('@/assets/fonts/poppins/Poppins-SemiBold.ttf');
}
</style>
<style lang="scss" scoped>
.container{
	width: 100%;
	//height: 100%;
}
.logo1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #FAFAFA;
}

.a-titulo{
	font-size: 17px;
}

.logo1 > a > img {
	width: 200px;
	height: auto;
	display: flex;
	margin-top: 35px;
	align-self: start;
	margin-left:40px
}

.logo_2 {
	display: flex;
	flex-direction: row;
	justify-content:space-around;
	margin-right:50px ;
	margin-top: auto;
}

.logo_2 > p {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	color: #343a40;
	margin-right: 20px;
	margin-top:10px
}

.logo_2 > #buttonCadastrar {
	display: flex;
	align-items: center;
	background: linear-gradient(180deg, #33b0d6 0%, rgba(51, 176, 214, 0) 100%), #22d630bf;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 1;
	letter-spacing: .4px;
	padding: 0 20px;
	height: 40px;
	border-radius: 9px;
	cursor: pointer;
	color: #FFFFFF;
	box-shadow: 0 10px 25px #74c9d7;
	border: none;
}

.logo_2 > #buttonCadastrar:hover{
	background: #00c4ff;
}

.logo_2 > #buttonCadastrar > span > a{
  color: #FFFFFF;
}

@media (max-width: 992px) {
	body{
		overflow: auto;
	}
	.logo_2 > p {
		display:none;
	}
	.logo_2 > #buttonCadastrar {
		width:200px;
		display:flex;
		justify-content:center;
		align-self:center;
	}

	.logo_2 > #buttonCadastrar {
		font-size:17px !important;
	}
	.limiter_1{
		margin-top:-100px;
	}
}

  @media (max-width: 576px) {
	.logo {
		width:100%;
		display:flex;
		justify-content:center;
		flex-direction:column;
		flex-wrap: wrap;
	}
	.logo1 {
		width:100%;
		display:flex;
		justify-content:center;
		flex-direction:column;
		flex-wrap: wrap;
	}

	.logo1 > a > img {
		width:80%;
		height: auto;
		display: flex;
		margin-top: 35px;
		margin-left: 20px;
	}

	.logo_2  {
		width:100%;
		display:flex;
		justify-content:center;
		flex-direction:column;
		flex-wrap: nowrap;
		margin-top: 20px;
	}
	.logo_2 >p {
		display:flex;
		justify-content:center;
		margin-bottom: 20px;
		margin-right:0px;
		}
  }

</style>
