/** When your routing table is too long, you can split it into small modules **/
import Layout from '../../views/layout-externo/layout'
const tableRouter = {
  path: '/usuario-externo',
  redirect: '/usuario-externo/',
  component: Layout,
  hidden: true,
  meta: {
    roles: ['externo']
  },
  children: [
    {
      path: 'protocolo',
      component: () => import('@/views/user-externo/index'),
      name: 'Meus Protocolos',
      title: 'Meus Protocolos'
    },
    {
      path: 'perfil',
      component: () => import('@/views/profile/index'),
      name: 'Perfil-Externo',
      title: 'Perfil'
    }
  ]
}
export default tableRouter

