var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _c("sidebar", { staticClass: "sidebar-container" }),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { hasTagsView: _vm.needTagsView },
        },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [_vm.needTagsView ? _c("tags-view") : _vm._e()],
            1
          ),
          _vm._m(0),
          _c("app-main"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { padding: "0 20px" } }, [
      _c("hr", {
        staticStyle: {
          border: "none",
          "border-top": "1px solid rgba(0, 0, 0, 0.1)",
        },
        attrs: { id: "linha-breadcumb" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }