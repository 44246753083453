var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "calc(100vh - 73.05px)" } },
    [
      _c(
        "keep-alive",
        { attrs: { include: _vm.cachedViews } },
        [_c("router-view", { key: _vm.key })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }