<template>
  <div v-if="showDetail" v-loading="detailLoading" class="processoDetail">
    ESTOU AQUI
    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <div class="title">{{ processo.tx_identificacao }}</div>
      </el-col>
      <el-col class="card-title" :span="18">
        {{ processo.nome_tipo_processo }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="18" class="small-info">
        {{ processo.bl_eletronico ? 'Eletrônico' : 'Não eletrônico' }} - {{ processo.tx_status | formatStatus }}
      </el-col>
    </el-row>
    <el-row>
      <p>{{ processo.tx_descricao }}</p>
    </el-row>
    <el-row type="flex" justify="space-between">
      <el-col :span="18">
        Localização atual: {{ processo.nome_unidade_funcional_atual }}
      </el-col>
      <el-col :span="6">
        {{ processo.nome_usuario_atual }} <svg-icon icon-class="user" class-name="card-panel-icon" />
      </el-col>
    </el-row>
    <el-row>
      Situação: {{ processo.nome_fase }}
    </el-row>
    <el-row>
      <el-tabs v-model="activeTab" v-loading="tabLoading" type="border-card" @tab-click="handleClick">
        <el-tab-pane name="pecas" label="Peças">
          <el-alert
            v-if="pecas.length <= 0"
            title="Sem peças"
            type="warning"
            effect="dark"
          />
          <el-row v-for="(item, index) in pecas" :key="item.id" class="tabs_row" :gutter="20">
            <el-col :span="12"> <div class="grid-content"> {{ index+1 }}. {{ item.tx_nome | parseNull }}</div></el-col>
            <el-col :span="4"> <div class="grid-content"> {{ item.tipo | parseValues('pecaCategoria') }} </div></el-col>
            <el-col :span="4"> <div class="grid-content"> {{ item.dt_criacao | formatDate('datetime') }} </div></el-col>
            <el-col :span="4">
              <el-tooltip placement="top-start" content="Baixar peça">
                <div class="icon-item click-item">
                  <svg-icon icon-class="peca-show" @click="fileClick(item.id)" />
                </div>
              </el-tooltip>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane name="vinculados" label="Vinculados">
          <el-alert
            v-if="vinculados.length <= 0"
            title="Sem vinculados"
            type="warning"
            effect="dark"
          />
          <el-row v-for="item in vinculados" :key="item.id" class="tabs_row" :gutter="20">

            <el-col :span="14"> <div class="grid-content"> {{ item.name }}</div></el-col>
            <el-col :span="8"> <div class="grid-content"> {{ item.dt_inicio | formatDate('datetime') }} </div></el-col>
            <el-col :span="2">
              <el-tooltip placement="top-start" content="Ver protocolo">
                <div class="icon-item click-item">
                  <svg-icon icon-class="processos" @click="loadProtocolo(item.id_processo_filho)" />
                </div>
              </el-tooltip>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane name="interessados" label="Interessados">
          <el-alert
            v-if="interessados.length <= 0"
            title="Sem interessados"
            type="warning"
            effect="dark"
          />
          <el-row v-for="item in interessados" :key="item.id" class="tabs_row" :gutter="20">
            <el-col :span="8"> <div class="grid-content"> {{ item.name }}</div></el-col>
            <el-col :span="4"> <div class="grid-content"> {{ item.number }} </div></el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane name="notas" label="Notas">
          <el-alert
            v-if="notas.length <= 0"
            title="Sem notas"
            type="warning"
            effect="dark"
          />
          <el-row v-for="(item, index) in notas" :key="item.id" class="tabs_row" :gutter="20">
            <el-col :span="2"> <div class="grid-content"> {{ index+1 }}</div></el-col>
            <el-col :span="8"> <div class="grid-content"> {{ item.name }}</div></el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane name="historico" label="Movimentação">
          <el-alert
            v-if="historico.length <= 0"
            title="Sem Movimentação"
            type="warning"
            effect="dark"
          />
          <el-row v-for="item in historico" :key="item.id" class="tabs_row" :gutter="20">
            <el-col :span="3">
              <div class="grid-content">
                <div class="icon-item">
                  <svg-icon :icon-class="item.tx_ocorrencia" />
                  <span>{{ item.tx_ocorrencia | codeFilter }}</span>

                </div>
              </div>
            </el-col>
            <el-col :span="21">
              <div class="grid-content history_block">
                <el-row :gutter="20">
                  <el-col :span="6"> <div class="grid-content"> Usuário: {{ item.usuario }} </div></el-col>
                  <el-col :span="6"> <div class="grid-content"> Data: {{ item.dt_ocorrencia | formatDate('datetime') }}</div></el-col>
                  <el-col :span="6"> <div class="grid-content"> Host: {{ item.tx_host }}</div></el-col>
                  <el-col :span="6"> <div class="grid-content"> IP: {{ item.tx_ip }}</div></el-col>
                </el-row>
                <span v-if="item.tx_ocorrencia === 'CR'">
                  <el-row :gutter="20">
                    <el-col :span="12"> <div class="grid-content"> Fase Origem: {{ item.fase }} </div></el-col>
                  </el-row>
                </span>
                <span v-else>
                  <el-row :gutter="20">
                    <el-col :span="12"> <div class="grid-content"> Fase Origem: {{ item.fase }} </div></el-col>
                  </el-row>
                </span>

                <span v-if="item.tx_ocorrencia !== 'SG' && item.tx_ocorrencia !== 'VS' && item.tx_ocorrencia !== 'CR'">
                  <el-row :gutter="20">
                    <el-col :span="12"> <div class="grid-content"> Usuário origem: {{ item.usuario_origem }}</div></el-col>
                    <el-col :span="12"> <div class="grid-content"> Usuário destino: {{ item.usuario_destino }} </div></el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12"> <div class="grid-content"> Unidade funcional: {{ item.unidade_funcional }} </div></el-col>
                    <el-col :span="12"> <div class="grid-content"> Unidade funcional destino: {{ item.unidade_funcional_destino }} </div></el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12"> <div class="grid-content"> Fase Origem: {{ item.fase }} </div></el-col>
                    <el-col :span="12"> <div class="grid-content"> Fase destino: {{ item.fase_destino }} </div></el-col>
                  </el-row>
                </span>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { findActions, getHistorico, getInteressados, getNotas, getVinculados, show } from '@/api/processo'
import { getPecas } from '@/api/processo-peca'
import { getURL } from '@/utils'

export default {
  name: 'ProtocoloDetails',
  filters: {
    formatDate(date, type) {
      if (type === 'date') {
        return (date) ? Vue.moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY') : ''
      } else {
        return (date) ? Vue.moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY HH:mm:ss') : ''
      }
    },
    codeFilter(code) {
      const textMap = {
        CR: 'Criação',
        PC: 'Peça',
        RL: 'Relacionar',
        TR: 'Tramitar',
        MF: 'Fase',
        DT: 'Distribuir',
        AQ: 'Arquivar',
        DQ: 'Desarquivar',
        VS: 'Visualizar',
        SG: 'Assinar',
        CL: 'Cancelar'
      }
      return textMap[code]
    },
    tipoFilter(code) {
      const textMap = {
        P: 'Criação',
        M: 'Peça'
      }
      return textMap[code]
    },
    formatStatus(status) {
      const textMap = {
        ST: 'Ativo',
        AT: 'Ativo',
        AQ: 'Arquivado',
        CL: 'Cancelado',
        JT: 'Juntado'
      }
      return textMap[status]
    },
    parseNull(value) {
      return value === 'null' ? '[ ]' : value
    }
  },
  data() {
    return {
      tabLoading: false,
      detailLoading: false,
      showDetail: false,
      activeTab: 'pecas',
      processo: {
        bl_eletronico: '',
        bl_sigiloso: '',
        dt_criacao: ''
      },
      peca: null,
      historico: [],
      pecas: [],
      vinculados: [],
      notas: [],
      operacoes: [],
      operacaoFields: [],
      interessados: []
    }
  },
  computed: {
    ...mapGetters([
      'user_id'
    ])
  },
  methods: {
    handleShow(processo) {
      if (Number.isInteger(processo)) {
        this.detailLoading = true
        show(processo).then(response => {
          this.detailLoading = false
          if (response.status) {
            processo = response.data
          }
        })
      }
      if (this.processo.id !== processo.id) {
        this.processo = processo
        this.loadTab()
        this.showDetail = true
      }
    },
    clear() {
      this.showDetail = false
    },
    loadProtocolo(id) {
      this.$emit('load:protocolo', id)
    },
    handleClick(tab, event) {
      this.loadTab(tab.name)
    },
    loadTab(tab = false) {
      this.tabLoading = true
      if (!tab) {
        tab = this.activeTab
      }
      switch (tab) {
        case 'historico':
          getHistorico(this.processo.id).then(response => {
            if (response.status) {
              this.historico = response.data
            }
            this.tabLoading = false
          })
          break
        case 'pecas':
          getPecas({ id_processo: this.processo.id }).then(response => {
            if (response.status) {
              this.pecas = response.data
            }
            this.tabLoading = false
          })
          break
        case 'interessados':
          getInteressados({ id_processo: this.processo.id }).then(response => {
            if (response.status) {
              this.interessados = response.data
            }
            this.tabLoading = false
          })
          break
        case 'vinculados':
          getVinculados(this.processo.id).then(response => {
            if (response.status) {
              this.vinculados = response.data
            }
            this.tabLoading = false
          })
          break
        case 'notas':
          getNotas(this.processo.id).then(response => {
            if (response.status) {
              this.notas = response.data
            }
            this.tabLoading = false
          })
          break
        default:
          this.tabLoading = false
          break
      }
    },
    fileClick(id) {
      window.open(getURL('download/peca/' + this.user_id + '/' + id), '_blank')
    },
    findTodo(id_operacao, tx_operacao) {
      this.detailLoading = true
      // set id operação for actions
      this.processo.id_operacao = id_operacao
      this.processo.tx_operacao = tx_operacao
      findActions({ id_operacao: id_operacao, id_processo: this.processo.id }).then(response => {
        if (response.status) {
          const data = response.data
          for (const elem in data) {
            this.operacaoFields.push(data[elem])
          }
          if (this.operacaoFields.length > 0) {
            this.$refs.acoes.handleShow(this.operacaoFields)
          }
        }
        this.detailLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.processoDetail{

  padding: 10px;
  margin-left: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.04) 0px 0px 6px;
  border: 1px solid #eee;

  .no-padding { .el-dropdown-menu__item{
    padding: 0;
  }
  }
  .small-info{
    font-size: 12px;
    color: #888;
  }
  .el-tabs{
    margin-top: 20px;
  }
  .card-title {
    font-size: 15px;
    font-weight: bold;
    margin-top: 8px;
  }
  .title {
    float: left;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 20px;
    font-size: 20px;
  }
  .icon-item {
    text-align: center;
    float: left;
    font-size: 20px;
    color: #24292e;
  }

  .click-item{
    cursor: pointer;
  }

  span {
    display: block;
    font-size: 16px;
    margin-top: 10px;
  }

  .el-tab-pane {
    .tabs_row{
      border-bottom: #DCDFE6 solid 1px;
      padding-bottom: 15px;
      margin-bottom: 15px;
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .history_block {
    .el-row {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

</style>
