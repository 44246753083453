/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout'

const tableRouter = {
  path: '/ajuda',
  component: Layout,
  redirect: '/ajuda/tutorial',
  name: 'Ajuda',
  title: 'Ajuda',
  meta: {
    icon: 'question-circle'
  },
  children: [
    {
      path: 'guia',
      name: 'Guia passo a passo',
      title: 'Guia passo a passo'
    },
    {
      path: 'central',
      name: 'Central de Ajuda',
      title: 'Central de Ajuda'
    },
    {
      path: 'tutorial',
      component: () => import('@/views/guide/index'),
      name: 'Vídeos',
      title: 'Vídeos'
    },
    {
      path: 'suporte',
      name: 'Suporte',
      title: 'Suporte'
    }
  ]
}
export default tableRouter
