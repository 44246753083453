<template>
  <el-dialog :title="title" :modal="false" :visible.sync="editVisible" width="60%">
    <el-form ref="dataForm" :model="temp" label-position="left" label-width="170px" style="margin-left:50px; margin-right: 50px;">
      <el-form-item label="Número da peça">
        <el-input v-model="temp.nr_numero" style="width:80%" />
        <div v-if="filterFormError('nr_numero')" class="el-form-item__error">
          {{ showMsgError('nr_numero') }}
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="editVisible = false">
        Cancelar
      </el-button>
      <el-button type="primary" @click="save()">
        Confirmar
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import Vue from 'vue'
import { parseApiError } from '@/utils'
import { numerar } from '@/api/processo-peca'
export default {
  name: 'PecaUpload',
  data() {
    return {
      peca: null,
      loading: false,
      editVisible: false,
      temp: {},
      custom_errors: {

      },
      title: ''
    }
  },
  methods: {
    handleShow(peca) {
      this.peca = peca
      this.title = 'Peça ' + this.peca.tx_nome + ' - Numerar Peça'
      this.editVisible = true
    },
    filterFormError(field) {
      if (this.custom_errors[field]) {
        return this.custom_errors[field].show
      }
      return false
    },
    showMsgError(field) {
      if (this.custom_errors[field]) {
        return this.custom_errors[field].msg
      }
    },
    resetTemp() {
      this.temp = {
      }
    },
    save() {
      this.loading = true
      numerar(this.peca.id, this.temp).then(response => {
        if (response.status) {
          this.$notify({
            title: 'Peça numerada',
            message: 'Sucesso!',
            type: 'success',
            duration: 2000
          })
          this.resetTemp()
          this.editVisible = false
          this.$emit('pecaNumbered', response.data)
        } else {
          this.custom_errors = {}
          const result = parseApiError(response.message, response.data)
          for (var x in result) {
            const theField = result[x].field.split('.')
            Vue.set(this.custom_errors, theField[1], { show: true, msg: result[x].msg })
          }
        }
        this.loading = false
      })
    }
  }
}
</script>
