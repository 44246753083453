var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "dashboard",
    },
    [
      _c("el-row", {
        staticStyle: { "margin-bottom": "20px" },
        attrs: { hidden: _vm.btn },
      }),
      _c(
        "el-row",
        { staticClass: "outer-block", attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "left-block",
              attrs: { span: 5, xs: 24, sm: 24, md: 8, lg: 6, xl: 5 },
            },
            [
              _vm.processoList === null || _vm.processoList.length === 0
                ? _c("el-alert", {
                    staticStyle: {
                      width: "100%",
                      display: "inline-block",
                      "vertical-align": "middle",
                    },
                    attrs: {
                      closable: false,
                      title: "Não existem protocolos alocados a sua unidade",
                      type: "info",
                    },
                  })
                : _vm._e(),
              _vm._l(_vm.processoList, function (processo) {
                return _c(
                  "div",
                  {
                    key: processo.id,
                    attrs: { id: "lineSpacing" },
                    on: {
                      click: function ($event) {
                        return _vm.detail(processo)
                      },
                    },
                  },
                  [
                    _c("listaProcessos", {
                      ref: "listaProcessoCard",
                      refInFor: true,
                      attrs: { processo: processo },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c(
            "el-col",
            {
              staticClass: "stick-block",
              attrs: { span: 19, xs: 24, sm: 24, md: 16, lg: 18, xl: 19 },
            },
            [
              _c("processoDetails", {
                ref: "protocoloDetails",
                on: {
                  "update:protocolo": _vm.abrirAtualizarProtocolo,
                  "acao:update": _vm.handleAcao,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("novo-protocolo", {
        ref: "novoProcoloco",
        attrs: { filters: _vm.filters },
        on: {
          "update:filters": function ($event) {
            _vm.filters = $event
          },
          "processo:finalizado": _vm.handleAtualizarProtocolo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }