<template>
  <div class="navbar">
    <div class="right-menu">
      <template v-if="device !== 'mobile'">
        <span class="right-menu-item">{{ usuario }}</span>

        <error-log class="errLog-container right-menu-item hover-effect" />

        <!-- <screenfull id="screenfull" class="right-menu-item hover-effect" /> -->
      </template>

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <svg-icon icon-class="person-circle" class="user-avatar" />
          <!-- <img :src="avatar + '?imageView2/1/w/80/h/80'" class="user-avatar" alt="avatar" /> -->
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/perfil/index">
            <el-dropdown-item>Perfil</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided>
            <span style="display: block" @click="logout">Sair</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ErrorLog from '@/components/ErrorLog'
import avatar from '@/assets/avatar.png'

export default {
  name: 'AppNavbar',
  components: {
    ErrorLog
  },
  data() {
    return { avatar, user: {}, isCollapse: false }
  },
  computed: {
    ...mapGetters(['sidebar', 'device', 'usuario']),
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    }
  },
  created() {
    this.getUser()
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    getUser() {
      this.user = {
        info: this.userInfo
      }
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.has-logo {
  float: left;
  width: 320px;
}

.navbar {
  height: 66px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  z-index: 1;
  float: unset;
  padding: 0.5rem 1rem;

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-weight: 700;
      font-size: 14px;
      color: rgb(133, 136, 141);
      vertical-align: middle;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      //   margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: #007bff;
        border-color: #007bff;

        .user-avatar {
          cursor: pointer;
          width: 24px;
          height: 32px;
          color: #fff;
          padding-left: 7px;
          padding-bottom: 2px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -12px;
          top: 20px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
