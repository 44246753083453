<template>
  <div>
    <div style="display: flex; padding-top: 16px">
      <div :class="{ 'has-logo': showLogo }">
        <logo v-if="!isCollapse" :collapse="isCollapse" />
      </div>
      <hamburger
        id="hamburger-container"
        :is-active="sidebar.opened"
        class="hamburger-container"
        @toggleClick="toggleSideBar" />
    </div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        background-color="#fff"
        text-color="#bfcbd9"
        :unique-opened="false"
        active-text-color="#409eff"
        :collapse-transition="false"
        mode="vertical">
        <search id="header-search" class="right-menu-item" />
        <div v-if="!isCollapse" :collapse="isCollapse" class="align-item-btn">
          <el-button  class="btn-primary" type="primary" icon="el-icon-plus"  style="font-size:14px" @click="abrirNovoProtocolo()">Novo Protocolo</el-button>
        </div>
        <sidebar-item v-for="route in outrosObjetos" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
    <div class="menu-rodape">
      <div class="menu-perfil">
        <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
          <span class="el-dropdown-link">
            <div v-if="user.avatar !== null ">
                <el-image class="circle" :src="getFullImageSrc()" />
            </div>
            <div v-else class="circle" :style="{ backgroundColor: randomColor }">
              {{ userInitials }}
            </div>
            <span v-if="!isCollapse" class="nome-usuario">{{ usuario }}</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/perfil/index">
              <el-dropdown-item>Perfil</el-dropdown-item>
            </router-link>
            <el-dropdown-item divided>
              <span style="display: block" @click="logout">Sair</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="menu-ajuda">
        <el-dropdown class="ajuda" trigger="click">
          <div style="cursor: pointer">
            <svg-icon icon-class="question-circle" class="icon-ajuda" />
            <span v-if="!isCollapse">Ajuda</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <router-link v-for="obj in objetosAjuda" :key="obj.path" :to="'/ajuda/' + obj.path">
              <el-dropdown-item>{{ obj.name }}</el-dropdown-item>
            </router-link>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <processos
        ref="processos"
        :before-load-view="false"
        :filters="filtros"
        :btn="false"
        />
  </div>
</template>

<script>
import Hamburger from '@/components/Hamburger'
import Search from '@/components/HeaderSearch'
import { mapGetters } from 'vuex'
import processos from '../../../views/dashboard/basic/components/Processos'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
export default {
  components: { SidebarItem, Search, Logo, Hamburger, processos},
  data() {
    return {
      user: { info: null, avatar: null },
      randomColor: this.getRandomColor(),
      filtros: {
        id_unidade_funcional: null,
        id_fluxo: null,
        nome_unidade_funcional: null,
        nome_fluxo: null
      }
    }
  },
  computed: {
    ...mapGetters(['permission_routes', 'sidebar', 'usuario', 'name', 'avatar', 'menu']),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    isCollapse() {
      return !this.sidebar.opened
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    userInitials() {
      const names = this.user.name.split(' ')
      if (names.length >= 2) {
        const firstName = names[0]
        const lastName = names[names.length - 1]
        return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
      }
      return names[0].charAt(0).toUpperCase()
    },
    objetosAjuda() {
      // Filtra objetos com name igual a 'Ajuda'
      return this.permission_routes.filter((obj) => obj.name === 'Ajuda')[0].children
    },
    outrosObjetos() {
      // Filtra objetos com name diferente de 'Ajuda'
      return this.permission_routes.filter((obj) => obj.name !== 'Ajuda')
    }
  },
  beforeMount() {
        const primeiroItem = this.menu[0]
        if (primeiroItem) {
            this.filtros.id_unidade_funcional = primeiroItem.id
            this.filtros.nome_unidade_funcional = primeiroItem.nome
            this.filtros.id_fluxo = primeiroItem.fluxos.length > 0 ? primeiroItem.fluxos[0].id : null
            this.filtros.nome_fluxo = primeiroItem.fluxos.length > 0 ? primeiroItem.fluxos[0].nome : null
        } else {
            this.filtros.id_unidade_funcional = null
            this.filtros.id_fluxo = null
        }
   },
  created() {
    this.getUser()
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    abrirNovoProtocolo() {
        this.$refs.processos.abrirNovoProtocolo()
    },
    getUser() {
      this.user = {
        info: this.userInfo,
        avatar: this.avatar,
        name: this.name
      }
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF'
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },
    getFullImageSrc() {
      return 'data:image/png;base64,' + this.user.avatar
    }
  }
}
</script>
<style lang="scss" scoped>
.hideSidebar .el-scrollbar {
  height: calc(100% - 154px) !important;
}
.hideSidebar .menu-rodape {
  height: 84px;
  display: block;
}

.hideSidebar .menu-perfil {
  display: block;
  width: 100%;
}

.hideSidebar .menu-ajuda {
  display: block;
  width: 100%;
  float: left;
}
.align-item-btn{
  padding: 18px 18px 10px;
  width: 100%;
  margin-left: 0;
  font-size:14px;
}
.hideSidebar .icon-ajuda {
  margin-right: 10px !important;
}
.el-scrollbar {
  height: calc(100% - 126px) !important;
}
.nome-usuario {
  float: left;
  padding-left: 10px;
  padding-top: 8px;
}

.circle {
  float: left;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  text-align: center;
  line-height: 33px;
  color: white;
  font-weight: bolder;
}

.has-logo {
  float: left;
  display: inline-block;
  width: 270px;
}

.hamburger-container {
  line-height: 46px;
  display: inline-block;
  //   height: 100%;
  float: right;
  padding-top: 8px !important;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;
}
.perfil {
  border-radius: 40%;
}
.menu-ajuda {
  float: right;
  padding-top: 9px;
  width: 30%;
  padding-right: 10px;
  text-align: right;
  display: inline-block;
}
.menu-rodape {
  padding-top: 16px;
  display: flex;
  height: 56px;
}
.menu-perfil {
  float: left;
  display: inline-block;
  width: 70%;
  padding-left: 10px;
  cursor: pointer;
}
.icon-ajuda {
  margin-right: 7px !important;
}
</style>
