var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "novo-protocolo" },
    [
      _vm.showDialog
        ? _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                title: _vm.gerarTitulo(),
                visible: _vm.showDialog,
                width: "500px",
                top: "28px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showDialog = $event
                },
              },
            },
            [
              _vm.protocolo
                ? _c(
                    "el-form",
                    {
                      ref: "novo-protocolo-form",
                      attrs: {
                        model: _vm.protocolo,
                        "label-position": "top",
                        rules: _vm.rules,
                        "label-width": "150px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "b",
                            {
                              staticStyle: {
                                "font-size": "20px",
                                "padding-left": "15px",
                              },
                            },
                            [_vm._v("Unidade funcional: ")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.protocolo.nome_unidade_funcional) +
                              " "
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "el-form-item-custom",
                              attrs: {
                                label: "Tipo de protocolo",
                                prop: "id_tipo_processo",
                              },
                            },
                            [
                              _c("custom-list", {
                                attrs: {
                                  "field-key": "id_tipo_processo",
                                  "field-value": _vm.protocolo.id_tipo_processo,
                                  "field-label":
                                    _vm.protocolo.nome_tipo_processo,
                                  "query-function": _vm.findTipoProcesso,
                                  filter: _vm.tipoProtocoloFiltros,
                                  config: {
                                    disabled:
                                      _vm.protocolo.hasOwnProperty("id"),
                                  },
                                },
                                on: {
                                  "update:fieldValue": function ($event) {
                                    return _vm.$set(
                                      _vm.protocolo,
                                      "id_tipo_processo",
                                      $event
                                    )
                                  },
                                  "update:field-value": function ($event) {
                                    return _vm.$set(
                                      _vm.protocolo,
                                      "id_tipo_processo",
                                      $event
                                    )
                                  },
                                  "update:fieldLabel": function ($event) {
                                    return _vm.$set(
                                      _vm.protocolo,
                                      "nome_tipo_processo",
                                      $event
                                    )
                                  },
                                  "update:field-label": function ($event) {
                                    return _vm.$set(
                                      _vm.protocolo,
                                      "nome_tipo_processo",
                                      $event
                                    )
                                  },
                                  "update:filter": function ($event) {
                                    _vm.tipoProtocoloFiltros = $event
                                  },
                                  "value:updated": _vm.handleModel,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "el-form-item-custom",
                              attrs: {
                                label: "Descrição",
                                prop: "tx_descricao",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea" },
                                model: {
                                  value: _vm.protocolo.tx_descricao,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.protocolo, "tx_descricao", $$v)
                                  },
                                  expression: "protocolo.tx_descricao",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "el-form-item-custom",
                                  attrs: {
                                    label: "Sigiloso?",
                                    prop: "bl_sigiloso",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                    },
                                    model: {
                                      value: _vm.protocolo.bl_sigiloso,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.protocolo,
                                          "bl_sigiloso",
                                          $$v
                                        )
                                      },
                                      expression: "protocolo.bl_sigiloso",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "el-form-item-custom",
                                  attrs: {
                                    label: "Eletronico?",
                                    prop: "bl_eletronico",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                    },
                                    model: {
                                      value: _vm.protocolo.bl_eletronico,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.protocolo,
                                          "bl_eletronico",
                                          $$v
                                        )
                                      },
                                      expression: "protocolo.bl_eletronico",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "el-form-item-custom",
                              attrs: {
                                label: "Interessados",
                                prop: "ids_interessados",
                              },
                            },
                            [
                              _c("custom-list", {
                                attrs: {
                                  "field-key": "ids_interessados",
                                  "field-value": _vm.protocolo.ids_interessados,
                                  "field-label":
                                    _vm.protocolo.nomes_interessados,
                                  "query-function": _vm.findTerceiros,
                                  config: { multipleList: true },
                                },
                                on: {
                                  "update:fieldValue": function ($event) {
                                    return _vm.$set(
                                      _vm.protocolo,
                                      "ids_interessados",
                                      $event
                                    )
                                  },
                                  "update:field-value": function ($event) {
                                    return _vm.$set(
                                      _vm.protocolo,
                                      "ids_interessados",
                                      $event
                                    )
                                  },
                                  "update:fieldLabel": function ($event) {
                                    return _vm.$set(
                                      _vm.protocolo,
                                      "nomes_interessados",
                                      $event
                                    )
                                  },
                                  "update:field-label": function ($event) {
                                    return _vm.$set(
                                      _vm.protocolo,
                                      "nomes_interessados",
                                      $event
                                    )
                                  },
                                  "value:updated": _vm.handleModel,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-secondary",
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.salvarProtocolo },
                    },
                    [_vm._v("Salvar")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }