var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        modal: false,
        visible: _vm.uploadVisible,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.uploadVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "peca-upload-form",
          staticStyle: { "margin-left": "50px", "margin-right": "50px" },
          attrs: {
            model: _vm.temp,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "170px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Tipo de " + (_vm.tipo === "peca" ? "peça" : "anexo"),
                prop: "id_tipo_peca",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "80%" },
                  attrs: {
                    filterable: "",
                    remote: "",
                    "remote-method": (data) => _vm.loadTipos(data),
                    loading: _vm.loading,
                    placeholder: "Digite para buscar",
                  },
                  on: { change: _vm.handleTipoPeca },
                  model: {
                    value: _vm.temp.id_tipo_peca,
                    callback: function ($$v) {
                      _vm.$set(_vm.temp, "id_tipo_peca", $$v)
                    },
                    expression: "temp.id_tipo_peca",
                  },
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "Vazio", value: null },
                  }),
                  _vm._l(_vm.tipoPecaList, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: {
                        label: item.label,
                        value: item.value,
                        autonum: item.bl_autonum,
                      },
                    })
                  }),
                ],
                2
              ),
              _vm.filterFormError("id_tipo_peca")
                ? _c("div", { staticClass: "el-form-item__error" }, [
                    _vm._v(
                      " " + _vm._s(_vm.showMsgError("id_tipo_peca")) + " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.showNumero
            ? _c(
                "el-form-item",
                { attrs: { label: "Número da peça" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%" },
                    model: {
                      value: _vm.temp.nr_numero,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "nr_numero", $$v)
                      },
                      expression: "temp.nr_numero",
                    },
                  }),
                  _vm.filterFormError("nr_numero")
                    ? _c("div", { staticClass: "el-form-item__error" }, [
                        _vm._v(
                          " " + _vm._s(_vm.showMsgError("nr_numero")) + " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "Arquivo" } },
            [
              _c(
                "el-upload",
                {
                  ref: "uploadField",
                  attrs: {
                    action: "",
                    accept: _vm.filesAccepted,
                    "http-request": _vm.addFile,
                    multiple: false,
                    limit: 1,
                    "file-list": _vm.fileList,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "trigger",
                        size: "small",
                        type: "primary",
                      },
                      slot: "trigger",
                    },
                    [_vm._v("Escolher arquivo")]
                  ),
                ],
                1
              ),
              _vm.filterFormError("file")
                ? _c("div", { staticClass: "el-form-item__error" }, [
                    _vm._v(" " + _vm._s(_vm.showMsgError("file")) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.uploadVisible = false
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.clicked },
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v("Confirmar")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }