import { getInfo, login } from '@/api/user'
import { resetRouter } from '@/router'
import { getToken, removeToken, setToken } from '@/utils/auth'

const state = {
  token: getToken(),
  name: '',
  usuario: '',
  id: null,
  avatar: '',
  introduction: '',
  roles: [],
  menu: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_USUARIO: (state, usuario) => {
    state.usuario = usuario
  },
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_MENU: (state, menu) => {
    state.menu = menu
    }

}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then(response => {
          if (response.status) {
            const { data } = response
            commit('SET_TOKEN', data.access_token)
            setToken(data.token_type + ' ' + data.access_token)
            resolve()
          } else {
            reject(response.message)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then(response => {
          const { data } = response
          if (!response.status) {
            // response.message: "not_authorized"
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Sessão expirada, faça login novamente!')
          }
          // roles must be a non-empty array
          const roles = data.roles
          if (!roles || roles.length <= 0) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Erro ao processar os dados do perfil do usuário!')
          }

          commit('SET_ROLES', roles)
          commit('SET_USUARIO', data.tx_usuario)
          commit('SET_NAME', data.tx_nome)
          commit('SET_ID', data.id)
          commit('SET_MENU', data.menu)
          commit(
            'SET_AVATAR',
            data.tx_path
          )
          commit('SET_INTRODUCTION', 'I am a super administrator')
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // user logout
  logout({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resetRouter()
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
