import request from '@/utils/request'

export function getHistorico(id_processo, query = {}) {
  return request({
    url: '/processo/historico/' + id_processo,
    method: 'get',
    params: query
  })
}

export function getUltimasTramitacoes(ids, query = {}) {
  const idsString = Array.isArray(ids) ? ids.join(',') : ids
  return request({
    url: `/processo/ultimasTramitacoes/${idsString}`,
    method: 'get',
    params: query
  })
}
export function getInteressados(query) {
  return request({
    url: '/processo/interessados',
    method: 'get',
    params: query
  })
}

export function getVinculados(id_processo) {
  return request({
    url: '/processo/vinculados/' + id_processo,
    method: 'get'
  })
}

export function getNotas(id_processo) {
  return request({
    url: '/processo/notas/' + id_processo,
    method: 'get'
  })
}

export function doActions(id, data) {
  return request({
    url: '/processo/realizar_acoes/' + id,
    method: 'post',
    data
  })
}

export function show(id) {
  return request({
    url: '/processos/' + id,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/processos',
    method: 'post',
    data
  })
}

export function storeExterno(data) {
  return request({
    url: '/processos',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function update(id, data) {
  return request({
    url: '/processos/' + id,
    method: 'put',
    data
  })
}

export function updateExterno(id, data) {
  return request({
    url: '/processos/' + id,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function gerarPecaUnica(id) {
  return request({
    url: '/processo/gerar_peca_unica/' + id,
    method: 'put'
  })
}

export function cancelar(id) {
  return request({
    url: '/processo/cancelar/' + id,
    method: 'put'
  })
}

export function find(query) {
  return request({
    url: '/processos',
    method: 'get',
    params: query
  })
}

export function encaminhar(id) {
  return request({
    url: '/processo/encaminhar/' + id,
    method: 'put'
  })
}

export function destroy(id) {
  return request({
    url: '/processos/' + id,
    method: 'delete'
  })
}

export function dashboard(query) {
  return request({
    url: '/processo/dashboard',
    method: 'get',
    params: query
  })
}

export function dashboardWithDate(query) {
  return request({
    url: '/processo/dashboardPorPeriodo',
    method: 'get',
    params: query
  })
}
