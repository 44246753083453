<template>
    <div class="sidebar-logo-container" :class="{ 'collapse': collapse }">
        <transition name="sidebarLogoFade">
            <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" :to="computedLink">
                <img v-if="logo_menor" :src="logo_menor" class="sidebar-logo" width="28" height="16">
            </router-link>
            <router-link v-else key="expand" class="sidebar-logo-link" :to="computedLink">
                <img v-if="logo" :src="logo" class="sidebar-logo">
            </router-link>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'SidebarLogo',
    props: {
        collapse: {
            type: Boolean,
            required: true
        },
        isExterno: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        const images = require.context('@/assets/', false, /\.png$/)
        return {
            title: 'celerdoc',
            logo: images(`./header.png`),
            logo_menor: images(`./logo_menor.png`)
        }
    },
    computed: {
        computedLink() {
            return this.isExterno ? '/usuario-externo/protocolo' : '/'
        }
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #FFF;
  text-align: center;
  overflow: hidden;
  padding-top: 10px;
  padding-left: 10px;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      float: left;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .block-title{
      float: left;
      width: 150px;
      height: 32px;
    }

    & .sidebar-title {
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 16px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
    & .sub-title{
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 16px;
      font-size: 10px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
