import { asyncRoutes, constantRoutes } from '@/router'
import Layout from '@/layout'
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 *
 * @param menu
 */
function mountRoutes(menu) {
  const newRoutes = []
  for (var x in menu) {
    newRoutes.push({
      path: `/setor/${menu[x].id}`,
      meta: {
        icon: 'unidades_funcionais'
      },
      alwaysShow: true,
      component: Layout,
      title: `${menu[x].nome} (${menu[x].count})`,
      name: `${menu[x].nome} (${menu[x].count})`,
      children: mountFluxos(menu[x].fluxos, menu[x].nome, menu[x].id)
    })
  }
  return newRoutes
}

/**
 *
 * @param fluxos
 */
function mountFluxos(fluxos, setor_name, setor_id) {
  const fluxoList = []
  for (var x in fluxos) {
    fluxoList.push({
      path: `fluxo/${fluxos[x].id}`,
      title: `${fluxos[x].nome} (${fluxos[x].count})`,
      name: `${setor_name} ${fluxos[x].nome} (${fluxos[x].count})`,
      component: () => import('@/views/processos/pre'),
      alwaysShow: true,
      children: mountFases(fluxos[x].fases, setor_name, setor_id, fluxos[x].nome, fluxos[x].id)
    })
  }
  return fluxoList
}

/**
 *
 * @param fases
 */
function mountFases(fases, setor_name, setor_id, fluxo_nome, fluxo_id) {
  const fasesList = []

  for (var x in fases) {
    fasesList.push({
      path: `fase/${fases[x].id}`,
      props: {
        idUnidadeFuncional: setor_id,
        nomeUnidadeFuncional: setor_name,
        idFluxo: fluxo_id,
        nomeFluxo: fluxo_nome,
        idFase: fases[x].id,
        nomeFase: fases[x].nome
      },
      component: () => import('@/views/processos/index'),
      title: `${fases[x].nome} (${fases[x].count})`,
      name: `${setor_name}-${fluxo_nome}-${fases[x].nome} (${fases[x].count})`
    })
  }
  return fasesList
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    const newRoutes = mountRoutes(roles.menu)
    return new Promise(resolve => {
      let accessedRoutes
      if (roles.includes('admin')) {
        accessedRoutes = asyncRoutes || []
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      }
      accessedRoutes = accessedRoutes.concat(newRoutes)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
