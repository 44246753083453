<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div v-if="showDetail" v-loading="detailLoading" class="box-shadow" style="padding: 12px 20px 12px 20px">
    <el-row type="flex" justify="space-between" :gutter="10">
      <el-col class="card-title" :span="16" style="text-align: left">
        <div class="card-processo-info">
             <el-row >
              <el-col class="title-fluxo" style="text-align: left">
                 <el-tag type="info" class="badge">
                    {{ processo.nome_tipo_processo }}
                </el-tag>
              </el-col>
            </el-row>
            <el-row >
              <el-col class="card-title" :span="24" style="text-align: left">
                <p class="title-custom">{{ processo.tx_identificacao }}</p>
              </el-col>
            </el-row>
        </div>
        <el-row>
          <el-col :span="18" class="small-info">
            <svg-icon icon-class="explicit" />
            {{ processo.bl_eletronico ? 'Eletrônico' : 'Não eletrônico' }} - {{ processo.tx_status | formatStatus }}
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 10px">
          <el-col :span="24" style="margin-top: 10px">
            <span class="title-texts same-line-texts subtitle">Localização atual:</span>
            {{ processo.nome_unidade_funcional_atual }}
          </el-col>
        </el-row>
      </el-col>
      <el-col v-if="podeEditar()" id="details-operacoes" :span="8" style="text-align: right">
        <el-dropdown :show-timeout="100" trigger="click">
          <el-button id="custom-operacoes-btn" class="btn-primary">
            Operações
            <i class="el-icon-caret-bottom" style="font-size: 12px" />
          </el-button>
          <el-dropdown-menu slot="dropdown" class="no-padding">
            <el-dropdown-item v-for="operacao in operacoes" :key="operacao.id">
              <el-button class="button-operacao" @click="findTodo(operacao.id, operacao.tx_nome)">
                {{ operacao.tx_nome }}
              </el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>

    <el-row :gutter="24" style="padding-bottom: 0.75rem">
      <el-col :span="12">
        <span class="title-texts same-line-texts subtitle">Situação:</span>
        {{ processo.nome_fase }}
      </el-col>
      <el-col :span="12" style="text-align: right">
        <span class="title-texts same-line-texts subtitle">
          <svg-icon icon-class="people-bi" class-name="" style="font-size: 18px" />
          {{ processo.nome_usuario_atual }}
        </span>
      </el-col>
    </el-row>
    <el-row id="details-tab" style="border-top: 1px solid rgba(0, 0, 0, 0.125)">
      <el-col :span="24">
        <el-tabs v-model="activeTab" v-loading="tabLoading" type="border-card" @tab-click="handleClick">
          <el-tab-pane name="tramitacao" label="Tramitação">
            <div v-if="tramitacaoList.length <= 0">
              <p>Sem Tramitações</p>
            </div>
            <el-row v-else>
              <el-table :data="tramitacaoList" height="470">
                <el-table-column label="#" prop="id" :width="75">
                  <template slot-scope="{ $index }">
                    {{ $index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column label="Origem" prop="unidade_funcional" :width="325">
                  <template slot-scope="{ row }">
                    {{ row.unidade_funcional }}
                  </template>
                </el-table-column>
                <el-table-column label="Destino" prop="unidade_funcional_destino" :width="325">
                  <template slot-scope="{ row }">
                    {{ row.unidade_funcional_destino }}
                  </template>
                </el-table-column>
                <el-table-column label="Data Entrada" prop="dt_ocorrencia" :width="175">
                  <template slot-scope="{ row }">
                    {{ row.dt_ocorrencia | formatDate('datetime') }}
                  </template>
                </el-table-column>
                <el-table-column label="Data Saída" prop="dt_ocorrencia" :width="175">
                  <template slot-scope="{ row }">
                    {{ row.dt_ocorrencia | formatDate('datetime') }}
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-tab-pane>
          <el-tab-pane name="pecas" label="Peças">
            <el-row class="tabs_row">
              <el-col :span="18">
                <el-button
                  v-if="podeEditar()"
                  size="medium"
                  class="btn-primary filter-item"
                  type="primary"
                  @click="showPecaUpload()">
                  <svg-icon icon-class="plus-square" />
                </el-button>
                <el-tooltip content="Gerar Peça Única" effect="dark" placement="top">
                  <el-button
                    v-if="podeEditar() && pecas.length > 0"
                    size="small"
                    class="btn-primary"
                    type="primary"
                    @click="gerarPecaUnica()">
                    Gerar peça única
                  </el-button>
                </el-tooltip>
              </el-col>
              <el-col :span="6">
                <el-switch
                  v-model="showOnlyPecaPendente"
                  active-text="Somente Peças pendentes"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="refreshPecas" />
              </el-col>
            </el-row>
            <div v-if="pecas.length <= 0">
              <p>Sem peças</p>
            </div>
            <el-row v-else>
              <el-table :data="pecas" height="470" >
                <el-table-column label="#" prop="id" width="70">
                  <template slot-scope="{ $index }">
                    {{ $index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column label="Peça" prop="tx_nome">
                  <template slot-scope="{ row }">
                    {{ row.tx_nome }}
                  </template>
                </el-table-column>
                <el-table-column label="Tipo" prop="tipo" width="100">
                  <template slot-scope="{ row }">
                    {{ row.nome_tipo_peca }}
                  </template>
                </el-table-column>
                <el-table-column label="Data" prop="dt_criacao">
                  <template slot-scope="{ row }">
                    {{ row.dt_criacao | formatDate('datetime') }}
                  </template>
                </el-table-column>
                <el-table-column label="Status" prop="nome_status"  width="100"/>
                <el-table-column label="Ação">
                  <template slot-scope="{ row }">
                    <div v-if="podeEditar()" class="action-icons">
                      <el-tooltip v-if="row.tx_status !== 'RM'" placement="top-start">
                        <div slot="content">Visualizar peça</div>
                        <div class="icon-container">
                          <svg-icon icon-class="search" @click="fileClick(row.id)" />
                        </div>
                      </el-tooltip>
                      <el-tooltip placement="top-start">
                        <div slot="content">Numerar peça</div>
                        <div class="icon-container">
                          <svg-icon icon-class="peca-edit" @click="showNumerarPeca(row)" />
                        </div>
                      </el-tooltip>

                      <el-tooltip v-if="actionPeca('numerar', row)" placement="top-start">
                        <div slot="content">Numerar peça</div>
                        <div class="icon-container">
                          <svg-icon icon-class="peca-edit" @click="showNumerarPeca(row)" />
                        </div>
                      </el-tooltip>

                      <el-tooltip v-if="actionPeca('deletar', row)" placement="top-start">
                        <div slot="content">Excluir peça</div>
                        <div class="icon-container">
                          <svg-icon icon-class="trash-bi" @click="deletePeca(row)" />
                        </div>
                      </el-tooltip>

                      <el-tooltip v-if="actionPeca('assinar', row)" placement="top-start">
                        <div slot="content">Assinar peça</div>
                        <div class="icon-container">
                          <svg-icon icon-class="pen" @click="assinarPeca(row)" />
                        </div>
                      </el-tooltip>

                      <el-tooltip v-if="actionPeca('solicitar-assinatura', row)" placement="top-start">
                        <div slot="content">Solicitar assinatura da peça</div>
                        <div class="icon-container">
                          <svg-icon icon-class="peca-request_sign" @click="solicitarAssinaturaPeca(row)" />
                        </div>
                      </el-tooltip>

                      <el-tooltip v-if="actionPeca('aprovar', row)" placement="top-start">
                        <div slot="content">Aprovar peça</div>
                        <div class="icon-container">
                          <svg-icon icon-class="peca-approve" @click="aprovarPeca(row)" />
                        </div>
                      </el-tooltip>

                      <el-tooltip v-if="actionPeca('remover', row)" placement="top-start">
                        <div slot="content">Remover peça</div>
                        <div class="icon-container">
                          <svg-icon icon-class="trash-bi" @click="removerPeca(row)" />
                        </div>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-tab-pane>
          <el-tab-pane name="vinculados" label="Vinculados">
            <div v-if="vinculados.length <= 0">
              <p>Sem vinculados</p>
            </div>
            <el-row v-else>
              <el-table class="table-custom" height="470" :data="vinculados">
                <el-table-column label="#" prop="id" />
                <el-table-column label="nome" prop="name" />
                <el-table-column label="Data Início" prop="dt_inicio">
                  <template slot-scope="{ row }">
                    {{ row.dt_inicio | formatDate('datetime') }}
                  </template>
                </el-table-column>
                <el-table-column label="Ação">
                  <template slot-scope="{ row }">
                    <div v-if="podeEditar()" class="action-icons">
                      <el-tooltip placement="top-start">
                        <div slot="content">Visualizar protocolo</div>
                        <div class="icon-container">
                          <svg-icon icon-class="search" @click="showProtocolo(row.id_processo_filho)" />
                        </div>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                />
              </el-table>
            </el-row>
          </el-tab-pane>
          <el-tab-pane name="interessados" label="Interessados">
            <div v-if="vinculados.length < 0">
              <p>Sem Interessados</p>
            </div>
            <el-row class="tabs_row smallfont_row">
              <el-table class="table-custom" height="470" :data="interessados">
                <el-table-column label="#" prop="id" />
                <el-table-column label="nome" prop="tx_nome_pessoa" />
                <el-table-column label="Documento" prop="cpf_cnpj">
                  <template slot-scope="{ row }">
                    {{ row.cpf_cnpj | parseCPF }}
                  </template>
                </el-table-column>
                />
              </el-table>
            </el-row>
          </el-tab-pane>
          <el-tab-pane name="notas" label="Notas">
            <el-row class="tabs_row">
              <el-col :span="24">
                <el-tooltip content="Adicionar Nota" effect="dark" placement="top">
                  <el-button v-if="podeEditar()" size="small" class="btn-primary" type="primary" @click="addNota()">
                    <svg-icon icon-class="plus-square" />
                    &nbsp;Adicionar Nota
                  </el-button>
                </el-tooltip>
              </el-col>
            </el-row>
            <el-table v-if="notas.length > 0" :data="notas" height="470">
              <el-table-column label="#">
                <template slot-scope="{ $index }">
                  {{ $index + 1 }}
                </template>
              </el-table-column>
              <el-table-column label="Data de Registro" prop="dt_registro">
                <template slot-scope="{ row }">
                  {{ row.dt_registro | formatDate('datetime') }}
                </template>
              </el-table-column>
              <el-table-column label="Usuário" prop="usuario" />
              <el-table-column label="Texto" prop="tx_texto" />
              <el-table-column label="Ação">
                <template slot-scope="{ row }">
                  <div v-if="podeEditar()" class="action-icons">
                    <el-tooltip placement="top-start">
                      <div slot="content">Excluir nota</div>
                      <div class="icon-container">
                        <svg-icon icon-class="trash-bi" @click="removeNota(row)" />
                      </div>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div v-else>
              <p>Sem notas</p>
            </div>
          </el-tab-pane>
          <el-tab-pane name="anexos" label="Anexos">
            <anexos ref="processoAnexos" :processo="processo" />
          </el-tab-pane>
          <el-tab-pane name="historico" label="Movimentação" class="historico-rows">
            <div v-if="historico.length <= 0">
              <p>Sem movimentação</p>
            </div>
            <div v-else>
              <el-row span="24">
                <el-table :data="historico" height="470">
                  <el-table-column label="Ocorrência" prop="tx_ocorrencia" :width="150">
                    <template slot-scope="{ row }">
                      <div class="action-itens click-item" style="cursor: default">
                        <span class="title-texts">
                          <svg-icon :icon-class="row.tx_ocorrencia" />
                          &nbsp;
                          {{ row.tx_ocorrencia | codeFilter }}
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Funcionário" prop="usuario" :width="350">
                    <template slot-scope="{ row }">
                      {{ row.usuario }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Data" prop="dt_ocorrencia" :width="150">
                    <template slot-scope="{ row }">
                      {{ row.dt_ocorrencia | formatDate('datetime') }}
                    </template>
                  </el-table-column>

                  <!-- <el-table-column label="Fase Origem" prop="fase" :width="250">
                    <template slot-scope="{ row }">
                      <div v-if="row.tx_ocorrencia === 'CR'" class="grid-content">
                        {{ row.fase }}
                      </div>
                    </template>
                  </el-table-column> -->
                  <el-table-column label="Fase Origem" prop="fase" :width="250">
                    <template slot-scope="scope">
                      <div v-if="scope.row.tx_ocorrencia === 'CR'" class="grid-content">
                        {{ scope.row.fase }}
                      </div>
                      <div v-else-if="scope.row.tx_ocorrencia !== 'SG' && scope.row.tx_ocorrencia !== 'VS'">
                        {{ scope.row.fase }}
                      </div>
                      <div v-else>
                          {{ scope.row.fase }}
                      </div>
                    </template>
                  </el-table-column>

                <el-table-column label="Fase destino" prop="fase_destino" :width="250">
                <template slot-scope="scope">
                    <div v-if="scope.row.tx_ocorrencia !== 'SG' && scope.row.tx_ocorrencia !== 'VS'">
                    {{ scope.row.fase_destino }}
                    </div>
                </template>
                </el-table-column>
                  <el-table-column label="Funcionário origem" prop="usuario_origem" :width="250">
                    <template slot-scope="scope">
                      <div v-if="scope.row.tx_ocorrencia !== 'SG' && scope.row.tx_ocorrencia !== 'VS'">
                        {{ scope.row.usuario_origem }}
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="Funcionário destino" prop="usuario_destino" :width="250">
                    <template slot-scope="scope">
                      <div v-if="scope.row.tx_ocorrencia !== 'SG' && scope.row.tx_ocorrencia !== 'VS'">
                        {{ scope.row.usuario_destino }}
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="Unidade funcional" prop="unidade_funcional" :width="250">
                    <template slot-scope="scope">
                      <div v-if="scope.row.tx_ocorrencia !== 'SG' && scope.row.tx_ocorrencia !== 'VS'">
                        {{ scope.row.unidade_funcional }}
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="Unidade funcional destino" prop="unidade_funcional_destino" :width="250">
                    <template slot-scope="scope">
                      <div v-if="scope.row.tx_ocorrencia !== 'SG' && scope.row.tx_ocorrencia !== 'VS'">
                        {{ scope.row.unidade_funcional_destino }}
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="Host" prop="tx_host" :width="150">
                    <template slot-scope="{ row }">
                      {{ row.tx_host }}
                    </template>
                  </el-table-column>
                  <el-table-column label="IP" prop="tx_ip" :width="150">
                    <template slot-scope="{ row }">
                      <label>IP:</label>
                      {{ row.tx_ip }}
                    </template>
                  </el-table-column>
                </el-table>
              </el-row>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <acoes ref="acoes" :processo.sync="processo" @acao:update="handleAcoes" />
    <pecaUpload ref="pecaUpload" :processo="processo" @addItem="handlePecaAdd" @updateItem="handlePecaUpdate" />
    <pecaEdit ref="editPeca" @pecaNumbered="updatePecaNumerada" />
    <notaAdd ref="notaAdd" :processo="processo" @nota:added="handleNota" />
    <pecaAssinar ref="pecaAssinar" @peca:signed="handlePecaSigned" />
    <processoDetail ref="vinculadoDetail" />
  </div>
</template>

<script>
import { MessageBox } from 'element-ui'
import Vue from 'vue'
import { mask } from 'vue-the-mask'
import { mapGetters } from 'vuex'

import { find as findAcoes } from '@/api/acao'
import { destroy } from '@/api/nota'
import { find as findOperacoes } from '@/api/operacao'
import { cancelar, gerarPecaUnica, getHistorico, getInteressados, getNotas, getVinculados } from '@/api/processo'
import { aprovarPeca, deletarPeca, getPecas, removerPeca } from '@/api/processo-peca'
import { parseCPF } from '@/utils'
import acoes from './Acoes'
import anexos from './Anexos'
import notaAdd from './NotaAdd'
import pecaAssinar from './PecaAssinar'
import pecaEdit from './PecaEdit'
import pecaUpload from './PecaUpload'
import processoDetail from './ProcessoDetail'

import { getURL } from '@/utils'

export default {
  name: 'ProtocoloDetails',
  directives: { mask, parseCPF },
  components: {
    pecaUpload,
    pecaEdit,
    acoes,
    notaAdd,
    processoDetail,
    pecaAssinar,
    anexos
  },
  filters: {
    formatDate(date, type) {
      if (type === 'date') {
        return date ? Vue.moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY') : ''
      } else {
        return date ? Vue.moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY HH:mm:ss') : ''
      }
    },
    tipoPeca(field) {
        const textMap = {
            ST: 'Ativo',
            AT: 'Ativo',
            AQ: 'Arquivado',
            CL: 'Cancelado',
            JT: 'Juntado'
        }
        return textMap[field]
    },
    codeFilter(code) {
      const textMap = {
        CR: 'Criação',
        PC: 'Peça',
        RL: 'Relacionar',
        TR: 'Tramitar',
        MF: 'Fase',
        DT: 'Distribuir',
        AQ: 'Arquivar',
        DQ: 'Desarquivar',
        VS: 'Visualizar',
        SG: 'Assinar',
        CL: 'Cancelar'
      }
      return textMap[code]
    },
    tipoFilter(code) {
      const textMap = {
        P: 'Criação',
        M: 'Peça'
      }
      return textMap[code]
    },
    formatStatus(status) {
      const textMap = {
        ST: 'Ativo',
        AT: 'Ativo',
        AQ: 'Arquivado',
        CL: 'Cancelado',
        JT: 'Juntado'
      }
      return textMap[status]
    },
    parseNull(value) {
      return value === 'null' ? '[ ]' : value
    }
  },
  data() {
    return {
      tabLoading: false,
      showOnlyPecaPendente: false,
      detailLoading: false,
      showDetail: false,
      activeTab: 'tramitacao',
      processo: {
        id: null,
        bl_eletronico: '',
        bl_sigiloso: '',
        dt_criacao: ''
      },
      peca: null,
      historico: [],
      pecas: [],
      vinculados: [],
      notas: [],
      operacoes: [],
      interessados: [],
      tramitacaoList: []
    }
  },
  computed: {
    ...mapGetters(['roles', 'user_id'])
  },
  methods: {
    // PECAS
    gerarPecaUnica() {
      MessageBox.confirm('Deseja mesmo gerar peça única?', 'Geração de peça única', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(() => {
        this.tabLoading = true
        gerarPecaUnica(this.processo.id).then((response) => {
          if (response.status) {
            window.open(getURL('download/peca_unica/' + this.processo.id), '_blank')
            this.$notify({
              title: 'Peça única gerada',
              message: 'Sucesso!',
              type: 'success',
              duration: 10000
            })
          } else {
            this.$notify({
              title: 'Peça única',
              message: response.message,
              type: 'error',
              duration: 20000
            })
          }
          this.tabLoading = false
        })
      })
    },
    fetchTramitacao(id = null) {
      this.localId = this.processoId
      if (this.localId && this.localId > 0) {
        this.tramitacaoList = []
        this.loading = true
        // only tramitação
        getHistorico(this.localId, { tramitacao: true }).then((response) => {
          this.loading = false
          if (response.status) {
            this.tramitacaoList = response.data
          }
        })
      }
    },
    actionPeca(tipo, item) {
      switch (tipo) {
        case 'numerar':
          return item.autonum_tipo_peca === 0
        case 'deletar':
          return item.tx_status === 'NA' || item.tx_status === 'AS' || item.tx_status === 'AD'
        case 'assinar':
          return (
            item.tx_status === 'AP' || item.tx_status === 'NA' || item.tx_status === 'AS' || item.tx_status === 'AD'
          )
        case 'solicitar-assinatura':
          return false // item.tx_status === 'NA' || item.tx_status === 'AS' || item.tx_status === 'AD'
        case 'aprovar':
          return (
            (item.tx_status === 'NA' || item.tx_status === 'AS') &&
            (this.roles.includes('admin') || this.roles.includes('confirmapeca'))
          ) // peca não aprovada ou assinada
        case 'remover':
          return item.tx_status === 'AP' && (this.roles.includes('admin') || this.roles.includes('desentranha'))
        default:
          return true
      }
    },
    showNumerarPeca(item) {
      this.$refs.editPeca.handleShow(item)
    },
    deletePeca(item) {
      MessageBox.confirm('Deseja mesmo excluir a peça?', 'Exclusão de peça', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(() => {
        this.tabLoading = true
        deletarPeca(item.id).then((response) => {
          if (response.status) {
            this.pecas.splice(this.pecas.indexOf(item), 1)
            this.$notify({
              title: 'Peça excluida',
              message: 'Sucesso!',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Peça',
              message: response.message,
              type: 'error',
              duration: 2000
            })
          }
          this.tabLoading = false
        })
      })
    },
    assinarPeca(item) {
      this.$refs.pecaAssinar.handleShow(item)
    },
    removerPeca(item) {
      MessageBox.confirm('Deseja mesmo remover a peça?', 'Remoção de peça', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(() => {
        this.tabLoading = true
        removerPeca(item.id).then((response) => {
          if (response.status) {
            this.pecas.splice(this.pecas.indexOf(item), 1, response.data)
            this.$notify({
              title: 'Peça removida',
              message: 'Sucesso!',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Peça',
              message: response.message,
              type: 'error',
              duration: 2000
            })
          }
          this.tabLoading = false
        })
      })
    },
    solicitarAssinaturaPeca(item) {},
    aprovarPeca(item) {
      const msg =
        item.tx_status === 'NA'
          ? 'A peça não foi assinada, deseja mesmo confirmar a peça?'
          : 'Deseja mesmo confirmar a peça?'
      MessageBox.confirm(msg, 'Aprovar peça', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(() => {
        this.tabLoading = true
        aprovarPeca(item.id).then((response) => {
          if (response.status) {
            this.pecas.splice(this.pecas.indexOf(item), 1, response.data)
            this.$notify({
              title: 'Peça aprovada',
              message: 'Sucesso!',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Peça',
              message: response.message,
              type: 'error',
              duration: 2000
            })
          }
          this.tabLoading = false
        })
      })
    },
    fileClick(id) {
      window.open(getURL('download/peca/' + this.user_id + '/' + id), '_blank')
    },
    handlePecaAdd(item) {
      this.pecas.push(item)
    },
    handlePecaSigned(item) {
      this.$alert('Documento assinado com sucesso!', '', {
        confirmButtonText: 'OK',
        type: 'success',
        center: true,
        callback: (action) => {
          this.handlePecaUpdate(item)
        }
      })
    },
    handlePecaUpdate(item) {
      let index = 0
      this.pecas.forEach((element, i) => {
        if (item.id === element.id) {
          index = i
        }
      })
      this.pecas.splice(index, 1, item)
    },
    updatePecaNumerada(item) {
      for (var x in this.pecas) {
        if (this.pecas[x].id === item.id) {
          this.pecas[x].tx_nome = item.tx_nome
        }
      }
    },
    showPecaUpload() {
      this.$refs.pecaUpload.handleShow()
    },
    showPecaEdit(item) {
      if (item.tx_status !== 'RM') {
        this.$refs.pecaUpload.handleShow(item)
      }
    },
    //
    podeEditar() {
      return this.processo.tx_status !== 'CL'
    },
    handleAcoes() {
      // pedir pra atualizar a lista de processos
      this.$emit('acao:update')
    },
    handleShow(processo, force = false) {
      if (this.processo.id !== processo.id || force) {
        this.processo = processo
        this.loadTab()
        this.getOperacoes()
        this.showDetail = true
        this.showOnlyPecaPendente = false
      }
    },
    hideDetail() {
      this.showDetail = false
    },
    handleClick(tab, event) {
      this.loadTab(tab.name)
    },
    refreshPecas() {
      getPecas({ id_processo: this.processo.id, tx_tipo: 'P', show_only_pendente: this.showOnlyPecaPendente }).then(
        (response) => {
          if (response.status) {
            this.pecas = response.data
          }
          this.tabLoading = false
        }
      )
    },
    loadTab(tab = false) {
      this.tabLoading = true
      if (!tab) {
        tab = this.activeTab
      }
      switch (tab) {
        case 'tramitacao':
          getHistorico(this.processo.id, { tramitacao: true }).then((response) => {
            if (response.status) {
              this.tramitacaoList = response.data
            }
            this.tabLoading = false
          })
          break
        case 'historico':
          getHistorico(this.processo.id).then((response) => {
            if (response.status) {
              this.historico = response.data
            }
            this.tabLoading = false
          })
          break
        case 'pecas':
          this.refreshPecas()
          break
        case 'interessados':
          getInteressados({ id_processo: this.processo.id }).then((response) => {
            if (response.status) {
              this.interessados = response.data
            }
            this.tabLoading = false
          })
          break
        case 'vinculados':
          getVinculados(this.processo.id).then((response) => {
            if (response.status) {
              this.vinculados = response.data
            }
            this.tabLoading = false
          })
          break
        case 'notas':
          getNotas(this.processo.id).then((response) => {
            if (response.status) {
              this.notas = response.data
            }
            this.tabLoading = false
          })
          break
        case 'anexos':
          this.tabLoading = false
          this.$refs.processoAnexos.refresh()
          break
        default:
          this.tabLoading = false
          break
      }
    },
    showProtocolo(id_processo) {
      this.$refs.vinculadoDetail.handleShow(id_processo)
      this.loadProtocolo(id_processo)
    },
    loadProtocolo(id) {
      this.$emit('load:protocolo', id)
    },
    getOperacoes() {
      this.detailLoading = true
      findOperacoes({ id_fase: this.processo.id_fase }).then((response) => {
        if (response.status) {
          this.operacoes = response.data
          if (this.roles.includes('admin')) {
            this.operacoes.unshift({
              id: -1,
              tx_nome: 'Cancelar Protocolo'
            })
          }
          this.operacoes.unshift({
            id: 0,
            tx_nome: 'Editar Protocolo'
          })
        }
        this.detailLoading = false
      })
    },
    findTodo(id_operacao, tx_operacao) {
      // cancelar protocolo
      if (id_operacao === -1) {
        MessageBox.confirm('Deseja mesmo cancelar o protocolo?', 'Cancelamento de protocolo', {
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          type: 'warning'
        }).then(() => {
          this.detailLoading = true
          cancelar(this.processo.id).then((response) => {
            if (response.status) {
              this.$emit('acao:update', response.data)
              this.$notify({
                title: 'Protocolo cancelado',
                message: 'Sucesso!',
                type: 'success',
                duration: 2000
              })
            } else {
              this.$notify({
                title: 'Protocolo',
                message: response.message,
                type: 'error',
                duration: 2000
              })
            }
            this.detailLoading = false
          })
        })
        // editar protocolo
      } else if (id_operacao === 0) {
        this.$emit('update:protocolo', this.processo)
      } else {
        this.detailLoading = true
        // set id operação for actions
        this.processo.id_operacao = id_operacao
        this.processo.tx_operacao = tx_operacao
        findAcoes({ id_operacao: id_operacao }).then((response) => {
          if (response.status && response.data.length > 0) {
            this.$refs.acoes.handleShow(response.data)
          } else {
            this.$notify({
              title: 'Erro ao buscar ações!',
              message: response.message,
              type: 'success',
              duration: 2000
            })
          }
          this.detailLoading = false
        })
      }
    },
    // NOTA
    addNota() {
      this.$refs.notaAdd.handleShow()
    },
    removeNota(nota) {
      MessageBox.confirm('Deseja mesmo excluir a nota?', 'Exclusão de nota', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(() => {
        this.detailLoading = true
        destroy(nota.id).then((response) => {
          this.detailLoading = false
          if (response.status) {
            this.notas.splice(this.notas.indexOf(nota), 1)
            this.$notify({
              title: 'Nota removida',
              message: 'Sucesso!',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Nota',
              message: response.message,
              type: 'error',
              duration: 2000
            })
          }
        })
      })
    },
    handleNota(nota) {
      this.notas.push(nota)
    }
  }
}
</script>
<style lang="scss">
.icon-container {
  width: 25px; /* Defina o tamanho desejado para os ícones */
  height: 25px;
  display: flex;
  justify-content: center; /* Alinha horizontalmente ao centro */
  align-items: center; /* Alinha verticalmente ao centro */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}
.card-processo-info{
    //border: 1px solid red;
    width: 250px;
    padding: 15px;
    padding-left: 0px;
    margin-bottom:10px;

}

.icon-container svg {
  width: 100%;
  height: 100%;
}
.el-table .cell {
  white-space: nowrap;
  overflow: visible;
}

.subtitle {
  color: #6c757d;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0px;
}
.el-tabs--border-card > .el-tabs__header {
  background-color: #fff;
  border-bottom: unset;
  margin: 0;
  color: #6c757d;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  margin: 10px;
  color: #6c757d;
  background-color: #fff;
  border: 2px solid #6c757d;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled):hover {
  color: #6c757d;
  font-weight: 700;
}
.el-tabs__item {
  color: #6c757d !important;
}

#details-operacoes {
  .el-dropdown-selfdefine {
    padding: 5px 3px;
    font-size: 12px;
  }
}
#pane-pecas {
  .peca_AS,
  .peca_AS .link-type {
    color: blue;
  }
  .peca_RM,
  .peca_RM .link-type {
    color: gray;
  }
  .peca_NA,
  .peca_NA .link-type {
    color: green;
  }
  .peca_AD,
  .peca_AD .link-type {
    color: red;
  }
  .peca_AP,
  .peca_AP .link-type {
    color: black;
  }
}
.peca-removida {
  text-decoration: line-through;
}
</style>
<style lang="scss" scoped>
#details-tab {
  .el-tab-pane {
    background-color: #fff;
    padding: 10px;
  }
  .historico-rows {
    //max-height: 300px;
    //overflow-y: scroll;
  }
}
.button-operacao {
  border-left: none;
  border-right: none;
  width: 100%;
}
.no-padding {
  .el-dropdown-menu__item {
    padding: 0;
  }
}
.small-info {
  font-size: 20px;
  color: #6c757d;
  font-weight: 500px;
}
.title-fluxo{
    color:#3182c1;
    font-size: 21px;
    font-weight: 500px;

}
.el-tabs {
  background-color: #fff;
}
.card-title {
  font-size: 15px;
  font-weight: bold;
}
.title-custom {
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 32px;
  margin-top: 16px;
}
.title {
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 8px;
  margin-right: 20px;
  font-size: 32px;
  margin-top: 16px;
}
.box-shadow {
  padding: 10px;
  margin-left: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.04) 0px 0px 6px;
  // border: 1px solid #eee;
  background-color: #fff;
}
.icon-item {
  text-align: center;
  font-size: 14px;
  color: #24292e;
}

.click-item {
  cursor: pointer;
  margin: 5px;
  padding: 2px;
}

#details-tab {
  .icon-item {
    float: left;
  }
}
span {
  display: block;
  margin-top: 10px;
}
.badge {
  padding: 0 10px;
  border-radius: 12px;
  color:#3182c1;
  font-size: 20px;
}
.el-tab-pane {
  .el-table thead {
    color: #000;
    font-weight: 500;
  }
  .tabs_row {
    //border-bottom: #DCDFE6 solid 1px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:last-child {
      border-bottom: none;
    }
  }
}
.el-tabs__header .is-top {
  background-color: #fff !important;
  //border-bottom: 1px solid #dfe4ed;
  margin: 0;
}
.el-tabs {
  margin-top: 20px;
  background-color: #fff;
}
.el-tabs--border-card {
  border: 0;
  background: #ffffff;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}
.action-icons {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 17px;
}
#custom-operacoes-btn {
  height: 45px;
  font-size: 1rem;
  padding: 6px 48px;
  font-weight: 400;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  font-size: 16px;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-size: 16px;
}
</style>
