var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showDetail
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading",
            },
          ],
          staticClass: "box-shadow",
          staticStyle: { padding: "12px 20px 12px 20px" },
        },
        [
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "space-between", gutter: 10 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "card-title",
                  staticStyle: { "text-align": "left" },
                  attrs: { span: 16 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "card-processo-info" },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "title-fluxo",
                              staticStyle: { "text-align": "left" },
                            },
                            [
                              _c(
                                "el-tag",
                                {
                                  staticClass: "badge",
                                  attrs: { type: "info" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.processo.nome_tipo_processo) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "card-title",
                              staticStyle: { "text-align": "left" },
                              attrs: { span: 24 },
                            },
                            [
                              _c("p", { staticClass: "title-custom" }, [
                                _vm._v(_vm._s(_vm.processo.tx_identificacao)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "small-info", attrs: { span: 18 } },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "explicit" },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.processo.bl_eletronico
                                  ? "Eletrônico"
                                  : "Não eletrônico"
                              ) +
                              " - " +
                              _vm._s(
                                _vm._f("formatStatus")(_vm.processo.tx_status)
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { span: 24 },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "title-texts same-line-texts subtitle",
                            },
                            [_vm._v("Localização atual:")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.processo.nome_unidade_funcional_atual
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.podeEditar()
                ? _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { id: "details-operacoes", span: 8 },
                    },
                    [
                      _c(
                        "el-dropdown",
                        { attrs: { "show-timeout": 100, trigger: "click" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn-primary",
                              attrs: { id: "custom-operacoes-btn" },
                            },
                            [
                              _vm._v(" Operações "),
                              _c("i", {
                                staticClass: "el-icon-caret-bottom",
                                staticStyle: { "font-size": "12px" },
                              }),
                            ]
                          ),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass: "no-padding",
                              attrs: { slot: "dropdown" },
                              slot: "dropdown",
                            },
                            _vm._l(_vm.operacoes, function (operacao) {
                              return _c(
                                "el-dropdown-item",
                                { key: operacao.id },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "button-operacao",
                                      on: {
                                        click: function ($event) {
                                          return _vm.findTodo(
                                            operacao.id,
                                            operacao.tx_nome
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(operacao.tx_nome) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticStyle: { "padding-bottom": "0.75rem" },
              attrs: { gutter: 24 },
            },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "span",
                  { staticClass: "title-texts same-line-texts subtitle" },
                  [_vm._v("Situação:")]
                ),
                _vm._v(" " + _vm._s(_vm.processo.nome_fase) + " "),
              ]),
              _c(
                "el-col",
                { staticStyle: { "text-align": "right" }, attrs: { span: 12 } },
                [
                  _c(
                    "span",
                    { staticClass: "title-texts same-line-texts subtitle" },
                    [
                      _c("svg-icon", {
                        staticStyle: { "font-size": "18px" },
                        attrs: { "icon-class": "people-bi", "class-name": "" },
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.processo.nome_usuario_atual) + " "
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticStyle: { "border-top": "1px solid rgba(0, 0, 0, 0.125)" },
              attrs: { id: "details-tab" },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-tabs",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tabLoading,
                          expression: "tabLoading",
                        },
                      ],
                      attrs: { type: "border-card" },
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { name: "tramitacao", label: "Tramitação" } },
                        [
                          _vm.tramitacaoList.length <= 0
                            ? _c("div", [_c("p", [_vm._v("Sem Tramitações")])])
                            : _c(
                                "el-row",
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        data: _vm.tramitacaoList,
                                        height: "470",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "#",
                                          prop: "id",
                                          width: 75,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ $index }) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s($index + 1) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          742654187
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Origem",
                                          prop: "unidade_funcional",
                                          width: 325,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.unidade_funcional
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          429274157
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Destino",
                                          prop: "unidade_funcional_destino",
                                          width: 325,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.unidade_funcional_destino
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2852050396
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Data Entrada",
                                          prop: "dt_ocorrencia",
                                          width: 175,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("formatDate")(
                                                          row.dt_ocorrencia,
                                                          "datetime"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          4180761104
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Data Saída",
                                          prop: "dt_ocorrencia",
                                          width: 175,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("formatDate")(
                                                          row.dt_ocorrencia,
                                                          "datetime"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          4180761104
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { name: "pecas", label: "Peças" } },
                        [
                          _c(
                            "el-row",
                            { staticClass: "tabs_row" },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 18 } },
                                [
                                  _vm.podeEditar()
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass:
                                            "btn-primary filter-item",
                                          attrs: {
                                            size: "medium",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showPecaUpload()
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "icon-class": "plus-square",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Gerar Peça Única",
                                        effect: "dark",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm.podeEditar() && _vm.pecas.length > 0
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "btn-primary",
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.gerarPecaUnica()
                                                },
                                              },
                                            },
                                            [_vm._v(" Gerar peça única ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-text": "Somente Peças pendentes",
                                      "active-color": "#13ce66",
                                      "inactive-color": "#ff4949",
                                    },
                                    on: { change: _vm.refreshPecas },
                                    model: {
                                      value: _vm.showOnlyPecaPendente,
                                      callback: function ($$v) {
                                        _vm.showOnlyPecaPendente = $$v
                                      },
                                      expression: "showOnlyPecaPendente",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.pecas.length <= 0
                            ? _c("div", [_c("p", [_vm._v("Sem peças")])])
                            : _c(
                                "el-row",
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: { data: _vm.pecas, height: "470" },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "#",
                                          prop: "id",
                                          width: "70",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ $index }) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s($index + 1) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          742654187
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Peça",
                                          prop: "tx_nome",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(row.tx_nome) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1325819557
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Tipo",
                                          prop: "tipo",
                                          width: "100",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.nome_tipo_peca
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3851260291
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Data",
                                          prop: "dt_criacao",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("formatDate")(
                                                          row.dt_criacao,
                                                          "datetime"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2952653959
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Status",
                                          prop: "nome_status",
                                          width: "100",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: { label: "Ação" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return [
                                                  _vm.podeEditar()
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "action-icons",
                                                        },
                                                        [
                                                          row.tx_status !== "RM"
                                                            ? _c(
                                                                "el-tooltip",
                                                                {
                                                                  attrs: {
                                                                    placement:
                                                                      "top-start",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        slot: "content",
                                                                      },
                                                                      slot: "content",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Visualizar peça"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "icon-container",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "svg-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "icon-class":
                                                                                "search",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.fileClick(
                                                                                  row.id
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "top-start",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  attrs: {
                                                                    slot: "content",
                                                                  },
                                                                  slot: "content",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Numerar peça"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "icon-container",
                                                                },
                                                                [
                                                                  _c(
                                                                    "svg-icon",
                                                                    {
                                                                      attrs: {
                                                                        "icon-class":
                                                                          "peca-edit",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.showNumerarPeca(
                                                                              row
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _vm.actionPeca(
                                                            "numerar",
                                                            row
                                                          )
                                                            ? _c(
                                                                "el-tooltip",
                                                                {
                                                                  attrs: {
                                                                    placement:
                                                                      "top-start",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        slot: "content",
                                                                      },
                                                                      slot: "content",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Numerar peça"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "icon-container",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "svg-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "icon-class":
                                                                                "peca-edit",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showNumerarPeca(
                                                                                  row
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.actionPeca(
                                                            "deletar",
                                                            row
                                                          )
                                                            ? _c(
                                                                "el-tooltip",
                                                                {
                                                                  attrs: {
                                                                    placement:
                                                                      "top-start",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        slot: "content",
                                                                      },
                                                                      slot: "content",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Excluir peça"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "icon-container",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "svg-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "icon-class":
                                                                                "trash-bi",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.deletePeca(
                                                                                  row
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.actionPeca(
                                                            "assinar",
                                                            row
                                                          )
                                                            ? _c(
                                                                "el-tooltip",
                                                                {
                                                                  attrs: {
                                                                    placement:
                                                                      "top-start",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        slot: "content",
                                                                      },
                                                                      slot: "content",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Assinar peça"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "icon-container",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "svg-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "icon-class":
                                                                                "pen",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.assinarPeca(
                                                                                  row
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.actionPeca(
                                                            "solicitar-assinatura",
                                                            row
                                                          )
                                                            ? _c(
                                                                "el-tooltip",
                                                                {
                                                                  attrs: {
                                                                    placement:
                                                                      "top-start",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        slot: "content",
                                                                      },
                                                                      slot: "content",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Solicitar assinatura da peça"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "icon-container",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "svg-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "icon-class":
                                                                                "peca-request_sign",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.solicitarAssinaturaPeca(
                                                                                  row
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.actionPeca(
                                                            "aprovar",
                                                            row
                                                          )
                                                            ? _c(
                                                                "el-tooltip",
                                                                {
                                                                  attrs: {
                                                                    placement:
                                                                      "top-start",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        slot: "content",
                                                                      },
                                                                      slot: "content",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Aprovar peça"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "icon-container",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "svg-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "icon-class":
                                                                                "peca-approve",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.aprovarPeca(
                                                                                  row
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.actionPeca(
                                                            "remover",
                                                            row
                                                          )
                                                            ? _c(
                                                                "el-tooltip",
                                                                {
                                                                  attrs: {
                                                                    placement:
                                                                      "top-start",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        slot: "content",
                                                                      },
                                                                      slot: "content",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Remover peça"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "icon-container",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "svg-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "icon-class":
                                                                                "trash-bi",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.removerPeca(
                                                                                  row
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          4121806261
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { name: "vinculados", label: "Vinculados" } },
                        [
                          _vm.vinculados.length <= 0
                            ? _c("div", [_c("p", [_vm._v("Sem vinculados")])])
                            : _c(
                                "el-row",
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticClass: "table-custom",
                                      attrs: {
                                        height: "470",
                                        data: _vm.vinculados,
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: { label: "#", prop: "id" },
                                      }),
                                      _c("el-table-column", {
                                        attrs: { label: "nome", prop: "name" },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Data Início",
                                          prop: "dt_inicio",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("formatDate")(
                                                          row.dt_inicio,
                                                          "datetime"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1148279128
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: { label: "Ação" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return [
                                                  _vm.podeEditar()
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "action-icons",
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "top-start",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  attrs: {
                                                                    slot: "content",
                                                                  },
                                                                  slot: "content",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Visualizar protocolo"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "icon-container",
                                                                },
                                                                [
                                                                  _c(
                                                                    "svg-icon",
                                                                    {
                                                                      attrs: {
                                                                        "icon-class":
                                                                          "search",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.showProtocolo(
                                                                              row.id_processo_filho
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2419778786
                                        ),
                                      }),
                                      _vm._v(" /> "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            name: "interessados",
                            label: "Interessados",
                          },
                        },
                        [
                          _vm.vinculados.length < 0
                            ? _c("div", [_c("p", [_vm._v("Sem Interessados")])])
                            : _vm._e(),
                          _c(
                            "el-row",
                            { staticClass: "tabs_row smallfont_row" },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "table-custom",
                                  attrs: {
                                    height: "470",
                                    data: _vm.interessados,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { label: "#", prop: "id" },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "nome",
                                      prop: "tx_nome_pessoa",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Documento",
                                      prop: "cpf_cnpj",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ row }) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("parseCPF")(
                                                      row.cpf_cnpj
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3756544827
                                    ),
                                  }),
                                  _vm._v(" /> "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { name: "notas", label: "Notas" } },
                        [
                          _c(
                            "el-row",
                            { staticClass: "tabs_row" },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Adicionar Nota",
                                        effect: "dark",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm.podeEditar()
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "btn-primary",
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addNota()
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "plus-square",
                                                },
                                              }),
                                              _vm._v("  Adicionar Nota "),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.notas.length > 0
                            ? _c(
                                "el-table",
                                { attrs: { data: _vm.notas, height: "470" } },
                                [
                                  _c("el-table-column", {
                                    attrs: { label: "#" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ $index }) {
                                            return [
                                              _vm._v(
                                                " " + _vm._s($index + 1) + " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      742654187
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Data de Registro",
                                      prop: "dt_registro",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ row }) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("formatDate")(
                                                      row.dt_registro,
                                                      "datetime"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1754194928
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Usuário",
                                      prop: "usuario",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "Texto", prop: "tx_texto" },
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "Ação" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ row }) {
                                            return [
                                              _vm.podeEditar()
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "action-icons",
                                                    },
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            placement:
                                                              "top-start",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                slot: "content",
                                                              },
                                                              slot: "content",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Excluir nota"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "icon-container",
                                                            },
                                                            [
                                                              _c("svg-icon", {
                                                                attrs: {
                                                                  "icon-class":
                                                                    "trash-bi",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeNota(
                                                                        row
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3999411672
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _c("div", [_c("p", [_vm._v("Sem notas")])]),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { name: "anexos", label: "Anexos" } },
                        [
                          _c("anexos", {
                            ref: "processoAnexos",
                            attrs: { processo: _vm.processo },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        {
                          staticClass: "historico-rows",
                          attrs: { name: "historico", label: "Movimentação" },
                        },
                        [
                          _vm.historico.length <= 0
                            ? _c("div", [_c("p", [_vm._v("Sem movimentação")])])
                            : _c(
                                "div",
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { span: "24" } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          attrs: {
                                            data: _vm.historico,
                                            height: "470",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Ocorrência",
                                              prop: "tx_ocorrencia",
                                              width: 150,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ row }) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "action-itens click-item",
                                                          staticStyle: {
                                                            cursor: "default",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "title-texts",
                                                            },
                                                            [
                                                              _c("svg-icon", {
                                                                attrs: {
                                                                  "icon-class":
                                                                    row.tx_ocorrencia,
                                                                },
                                                              }),
                                                              _vm._v(
                                                                "   " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "codeFilter"
                                                                    )(
                                                                      row.tx_ocorrencia
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              536542611
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Funcionário",
                                              prop: "usuario",
                                              width: 350,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ row }) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(row.usuario) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2827672153
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Data",
                                              prop: "dt_ocorrencia",
                                              width: 150,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ row }) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatDate"
                                                            )(
                                                              row.dt_ocorrencia,
                                                              "datetime"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              4180761104
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Fase Origem",
                                              prop: "fase",
                                              width: 250,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      scope.row
                                                        .tx_ocorrencia === "CR"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "grid-content",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .fase
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : scope.row
                                                            .tx_ocorrencia !==
                                                            "SG" &&
                                                          scope.row
                                                            .tx_ocorrencia !==
                                                            "VS"
                                                        ? _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.row.fase
                                                                ) +
                                                                " "
                                                            ),
                                                          ])
                                                        : _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.row.fase
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              902019831
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Fase destino",
                                              prop: "fase_destino",
                                              width: 250,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      scope.row
                                                        .tx_ocorrencia !==
                                                        "SG" &&
                                                      scope.row
                                                        .tx_ocorrencia !== "VS"
                                                        ? _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .fase_destino
                                                                ) +
                                                                " "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              957878687
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Funcionário origem",
                                              prop: "usuario_origem",
                                              width: 250,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      scope.row
                                                        .tx_ocorrencia !==
                                                        "SG" &&
                                                      scope.row
                                                        .tx_ocorrencia !== "VS"
                                                        ? _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .usuario_origem
                                                                ) +
                                                                " "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1719836029
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Funcionário destino",
                                              prop: "usuario_destino",
                                              width: 250,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      scope.row
                                                        .tx_ocorrencia !==
                                                        "SG" &&
                                                      scope.row
                                                        .tx_ocorrencia !== "VS"
                                                        ? _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .usuario_destino
                                                                ) +
                                                                " "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              689076808
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Unidade funcional",
                                              prop: "unidade_funcional",
                                              width: 250,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      scope.row
                                                        .tx_ocorrencia !==
                                                        "SG" &&
                                                      scope.row
                                                        .tx_ocorrencia !== "VS"
                                                        ? _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .unidade_funcional
                                                                ) +
                                                                " "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2007361261
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label:
                                                "Unidade funcional destino",
                                              prop: "unidade_funcional_destino",
                                              width: 250,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      scope.row
                                                        .tx_ocorrencia !==
                                                        "SG" &&
                                                      scope.row
                                                        .tx_ocorrencia !== "VS"
                                                        ? _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .unidade_funcional_destino
                                                                ) +
                                                                " "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              362886332
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Host",
                                              prop: "tx_host",
                                              width: 150,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ row }) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(row.tx_host) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2646145132
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "IP",
                                              prop: "tx_ip",
                                              width: 150,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ row }) {
                                                    return [
                                                      _c("label", [
                                                        _vm._v("IP:"),
                                                      ]),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(row.tx_ip) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2138910467
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("acoes", {
            ref: "acoes",
            attrs: { processo: _vm.processo },
            on: {
              "update:processo": function ($event) {
                _vm.processo = $event
              },
              "acao:update": _vm.handleAcoes,
            },
          }),
          _c("pecaUpload", {
            ref: "pecaUpload",
            attrs: { processo: _vm.processo },
            on: {
              addItem: _vm.handlePecaAdd,
              updateItem: _vm.handlePecaUpdate,
            },
          }),
          _c("pecaEdit", {
            ref: "editPeca",
            on: { pecaNumbered: _vm.updatePecaNumerada },
          }),
          _c("notaAdd", {
            ref: "notaAdd",
            attrs: { processo: _vm.processo },
            on: { "nota:added": _vm.handleNota },
          }),
          _c("pecaAssinar", {
            ref: "pecaAssinar",
            on: { "peca:signed": _vm.handlePecaSigned },
          }),
          _c("processoDetail", { ref: "vinculadoDetail" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }