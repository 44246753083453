import request from '@/utils/request'

export function login(data) {
  data.grant_type = 'password'
  data.client_id = process.env.VUE_APP_CLIENT_ID
  data.client_secret = process.env.VUE_APP_CLIENT_SECRET

  return request({
    url: '/login',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/meus_dados',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/logout',
    method: 'get'
  })
}

export function find(query) {
  return request({
    url: '/usuarios',
    method: 'get',
    params: query
  })
}

export function show(id) {
  return request({
    url: '/usuarios/' + id,
    method: 'get'
  })
}

export function updateMe(data) {
  return request({
    url: '/atualizar_dados',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function recoverPassword(data) {
  return request({
    url: '/recuperar_senha',
    method: 'post',
    data
  })
}

export function store(data) {
  return request({
    url: '/usuarios',
    method: 'post',
    data
  })
}

export function update(id, data) {
  return request({
    url: '/usuarios/' + id,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: '/usuarios/' + id,
    method: 'delete'
  })
}

export function ativarUsuario(token) {
  return request({
    url: '/ativar/' + token,
    method: 'get'
  })
}
