<template>
    <div class="setores-container">
        <div v-for="(setor, index) in setoresVisiveis" :key="index" :class="getSetaClass(index)">
            <h5>{{ setor.unidade_funcional_destino_sigla }}</h5>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SetoresList',
    props: {
        setores: {
            type: Array,
            required: true
        }
    },
    computed: {
        setoresVisiveis() {
            // Exibe apenas os últimos 4 setores
            return this.setores.slice(-4)
        }
    },
    methods: {
        getSetaClass(index) {
            const baseClasses = ['seta1', 'seta2', 'seta3', 'seta4']
            const flexClass = this.setores.length >= 2 ? 'flex-item' : ''
            return `${baseClasses[index]} ${flexClass} ${index > 0 ? 'mx-3' : ''}`
        }
    }
}
</script>

<style scoped>
  .setores-container {
    display: flex;
    flex-wrap: wrap;
    padding-right: 15px;
    margin-bottom: 0.75rem;
  }
  .seta {
      background-color: #000; /* Cor inicial da primeira seta */
      color: white;
      padding: 5px;
      font-weight: bold;
      text-align: center;
      position: relative;
  }

  .seta:not(:last-child)::after,
  .seta:last-child::after {
      content: '';
      position: absolute;
      top: 0;
      right: -15px; /* Ajuste para a ponta da seta */
      width: 0;
      height: 0;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 15px solid #000; /* Cor para separar as setas */
  }

  .seta1 {
      background-color: #87CEFA; /* Cor inicial da primeira seta */
      color: white;
      padding: 5px;
      font-weight: bold;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
  }

  .seta1:not(:last-child)::after,
  .seta1:last-child::after {
      content: '';
      position: absolute;
      top: 0;
      right: -15px; /* Ajuste para a ponta da seta */
      width: 0;
      height: 0;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 15px solid #87CEFA; /* Cor para separar as setas */
  }

  .seta2 {
      background-color: #6CA6CD; /* Cor inicial da primeira seta */
      color: white;
      padding: 5px;
      font-weight: bold;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
  }

  .seta2:not(:last-child)::after,
  .seta2:last-child::after {
      content: '';
      position: absolute;
      top: 0;
      right: -15px; /* Ajuste para a ponta da seta */
      width: 0;
      height: 0;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 15px solid #6CA6CD; /* Cor para separar as setas */
  }

  .seta3 {
      background-color: #4682B4; /* Cor inicial da primeira seta */
      color: white;
      padding: 5px;
      font-weight: bold;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
  }

  .seta3:not(:last-child)::after,
  .seta3:last-child::after {
      content: '';
      position: absolute;
      top: 0;
      right: -15px; /* Ajuste para a ponta da seta */
      width: 0;
      height: 0;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 15px solid #4682B4; /* Cor para separar as setas */
  }

  .seta4 {
      background-color: #4169E1; /* Cor inicial da primeira seta */
      color: white;
      padding: 5px;
      font-weight: bold;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
  }

  .seta4:not(:last-child)::after,
  .seta4:last-child::after {
      content: '';
      position: absolute;
      top: 0;
      right: -15px; /* Ajuste para a ponta da seta */
      width: 0;
      height: 0;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 15px solid #4169E1; /* Cor para separar as setas */
  }

  .seta:first-child {
      background-color: #87CEFA; /* Cor da primeira seta */
  }

  .seta:nth-child(2) {
      background-color: #6CA6CD; /* Cor da segunda seta */
  }

  .seta:nth-child(3) {
      background-color: #4682B4; /* Cor da terceira seta */
  }

  .seta:last-child {
      background-color: #4169E1; /* Cor da última seta */
  }

  .seta:last-child::after {
      border-left-color: white; /* Ponta da última seta */
  }

  /* Ocultar todas as setas, exceto as quatro últimas */
  .seta:nth-last-child(n+5) {
      display: none;
  }

  /* Margem esquerda */
  .ml-3, .mx-3 {
      margin-left: 1rem !important;
  }

  .flex-item {
  flex: 1;
}
</style>
