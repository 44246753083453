<template>
  <div class="executar-acao">
    <el-dialog
      v-if="showDialog"
      v-loading="loading"
      :title="gerarTitulo()"
      :visible.sync="showDialog"
      width="500px"
      top="28px">
      <el-form ref="acaoForm" :rules="rules" :model="acaoModel" label-width="215px" label-position="top">
        <el-row>
          <el-form-item v-if="checarCampo('id_usuario')" label="Funcionário de destino" prop="id_usuario">
            <custom-list
              field-key="id_usuario"
              :field-value.sync="acaoModel.id_usuario"
              :query-function="findUsuarios"
              :filter.sync="usuarioFiltro"
              @value:updated="handleModel" />
          </el-form-item>
          <el-form-item
            v-if="checarCampo('id_unidade_funcional_destino')"
            label="Unidade funcional destino"
            prop="id_unidade_funcional_destino">
            <custom-list
              field-key="id_unidade_funcional_destino"
              :field-value.sync="acaoModel.id_unidade_funcional_destino"
              :query-function="findUnidades"
              :filter.sync="unidadeFiltro"
              :config="{ disabled: unidadeDestinoDisabled }"
              @value:updated="handleModel" />
          </el-form-item>
          <el-form-item v-if="checarCampo('id_processo_filho')" label="Processo para juntar" prop="id_processo_filho">
            <custom-list
              field-key="id_processo_filho"
              :field-value.sync="acaoModel.id_processo_filho"
              :query-function="findProcessos"
              :filter.sync="processoFiltro"
              @value:updated="handleModel" />
          </el-form-item>
          <el-form-item v-if="checarCampo('arquivar')" label="Arquivar">
            <el-tag type="success">Sim</el-tag>
          </el-form-item>
          <el-form-item v-if="checarCampo('desarquivar')" label="Desarquivar">
            <el-tag type="success">Sim</el-tag>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">Cancelar</el-button>
        <el-button type="primary" @click="fazerAcao">Salvar</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import customList from '@/components/CustomList'

import { doActions, find as findProcessos } from '@/api/processo'
import { find as findUnidades } from '@/api/unidade-funcional'
import { find as findUsuarios } from '@/api/user'

import { createRule } from '@/utils'
import { Message } from 'element-ui'

export default {
  name: 'Acoes',
  components: { customList },
  props: {
    processo: {
      type: Object,
      default() {
        return {}
      }
    },
    acoes: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      loading: false,
      findUsuarios,
      findUnidades,
      findProcessos,
      createRule,
      showDialog: false,
      acaoModel: {
        id_usuario: null,
        id_unidade_funcional_destino: null,
        id_processo_filho: null,
        id_operacao: null
      },
      unidadeFiltro: {
        ignore_id: this.processo.id_unidade_funcional_atual
      },
      usuarioFiltro: {
        id_unidade_funcional: this.processo.id_unidade_funcional_atual
      },
      processoFiltro: {
        ignore_id: this.processo.id,
        id_unidade_funcional_atual: this.processo.id_unidade_funcional_atual
      },
      rules: {},
      camposObrigatorios: [],
      unidadeDestinoDisabled: false,
      title: null
    }
  },
  methods: {
    gerarTitulo() {
      return this.processo ? 'Protocolo ' + this.processo.tx_identificacao + ' - ' + this.processo.tx_operacao : ''
    },
    handleUpdateAcao() {
      // avisar que terminou a ação e atualizar a lista
      this.$emit('acao:update')
    },
    handleModel(obj) {
      this.acaoModel[obj.key] = obj.value
    },
    checarCampo(campo) {
      return this.camposObrigatorios.includes(campo)
    },
    handleShow(acoes) {
      // 'Não existem ações associadas para essa operação!'
      // RESET
      this.acaoModel = {
        id_usuario: null,
        id_unidade_funcional_destino: null,
        id_processo_filho: null,
        id_operacao: this.processo.id_operacao
      }
      this.camposObrigatorios = []
      this.unidadeDestinoDisabled = false
      this.rules = {}

      acoes.forEach((acao) => {
        switch (acao.tx_tipo_acao) {
          case 'DT':
            this.camposObrigatorios.push('id_usuario')
            this.rules.id_usuario = createRule([{ label: 'Funcionário de destino', type: 'required' }])
            break
          case 'TR': // TRAMITAR
            this.camposObrigatorios.push('id_unidade_funcional_destino')
            if (acao.id_unidade_funcional_destino) {
              this.acaoModel.id_unidade_funcional_destino = acao.id_unidade_funcional_destino
              this.unidadeDestinoDisabled = true
            }
            this.rules.id_unidade_funcional_destino = createRule([
              { label: 'Unidade funcional de destino', type: 'required' }
            ])
            break
          case 'RF':
            this.camposObrigatorios.push('id_processo_filho')
            this.rules.id_unidade_funcional_destino = createRule([{ label: 'Processo para juntar', type: 'required' }])
            break
          case 'AQ':
          case 'JT':
            this.camposObrigatorios.push('arquivar')
            break
          case 'DQ':
            this.camposObrigatorios.push('desarquivar')
            break
        }
      })
      this.showDialog = true
      if (this.camposObrigatorios.length === 0) {
        this.fazerAcao()
      }
    },
    fazerAcao() {
      this.$refs.acaoForm.validate((valid) => {
        if (valid) {
          this.loading = true
          doActions(this.processo.id, this.acaoModel).then((response) => {
            this.loading = false
            if (response.status) {
              Message({
                message: 'Protocolo atualizado: ' + this.processo.tx_identificacao,
                type: 'success',
                showClose: true,
                duration: 10000
              })
              this.showDialog = false
              this.$emit('acao:update')
            } else {
              Message({
                message: 'Erro: ' + response.message,
                type: 'warning',
                showClose: true,
                duration: 10000
              })
            }
          })
        }
      })
    }
  }
}
</script>
 <style lang="scss" scoped>
.el-switch.is-checked .el-switch__core {
  border-color: #007bff;
  background-color: #007bff;
}
.el-form--label-top .el-form-item__label {
  padding: 0 0 10px 0;
}
.el-form-item--medium .el-form-item__label {
  line-height: 24px;
}
.el-form--label-top .el-form-item__label {
  padding: 0;
}
.el-form-item__label {
  padding: 0;
}
$dark_gray: #889aa4;
.show-pwd {
  position: absolute;
  right: 10px;
  top: 2px;
  font-size: 16px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.el-form-item {
  margin-bottom: 1rem;
}
</style>
