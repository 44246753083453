var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      style: [
        _vm.localConfig.disabled
          ? { opacity: "0.8", "pointer-events": "none" }
          : { opacity: "1" },
      ],
    },
    [
      !_vm.selectVisible && _vm.localSelectedLabel
        ? _c(
            "el-tooltip",
            {
              attrs: {
                disabled: !_vm.noDataFound,
                content: _vm.noDataFound,
                effect: "dark",
                placement: "top",
              },
            },
            [
              _c(
                "el-button",
                {
                  class: _vm.listClass,
                  style:
                    "text-align: left; width: " +
                    (_vm.localConfig.buttonWidth ||
                      _vm.localConfig.manualWidth),
                  on: { click: _vm.showSelect },
                },
                [_vm._v(" " + _vm._s(_vm.localSelectedLabel) + " ")]
              ),
            ],
            1
          )
        : _c(
            "el-select",
            {
              ref: _vm.fieldKey,
              class: _vm.listClass,
              style: "width: " + _vm.localConfig.manualWidth,
              attrs: {
                disabled: _vm.localConfig.disabled,
                filterable: "",
                remote: "",
                clearable: _vm.localConfig.clearable,
                multiple: _vm.localConfig.multipleList,
                "multiple-limit": _vm.localConfig.multipleLimit,
                placeholder: _vm.localConfig.placeholder,
                "allow-create": _vm.localConfig.allowCreate,
                "popper-append-to-body": "",
                "remote-method": (query) => _vm.remoteFind(query),
              },
              on: {
                change: _vm.handleChange,
                "visible-change": _vm.handleVisibleChange,
                clear: _vm.handleClear,
                blur: _vm.resetList,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return ((value) => _vm.handleChange()).apply(null, arguments)
                },
              },
              model: {
                value: _vm.localSelectedValue,
                callback: function ($$v) {
                  _vm.localSelectedValue = $$v
                },
                expression: "localSelectedValue",
              },
            },
            _vm._l(_vm.getOptionsList(), function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }