import { render, staticRenderFns } from "./ProcessoDetails.vue?vue&type=template&id=3001f8bf&scoped=true"
import script from "./ProcessoDetails.vue?vue&type=script&lang=js"
export * from "./ProcessoDetails.vue?vue&type=script&lang=js"
import style0 from "./ProcessoDetails.vue?vue&type=style&index=0&id=3001f8bf&lang=scss"
import style1 from "./ProcessoDetails.vue?vue&type=style&index=1&id=3001f8bf&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3001f8bf",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3001f8bf')) {
      api.createRecord('3001f8bf', component.options)
    } else {
      api.reload('3001f8bf', component.options)
    }
    module.hot.accept("./ProcessoDetails.vue?vue&type=template&id=3001f8bf&scoped=true", function () {
      api.rerender('3001f8bf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/processos/components/ProcessoDetails.vue"
export default component.exports