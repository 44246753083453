var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.errorLogs.length > 0
    ? _c(
        "div",
        [
          _c(
            "el-badge",
            {
              staticStyle: { "line-height": "25px", "margin-top": "-5px" },
              attrs: { "is-dot": true },
              nativeOn: {
                click: function ($event) {
                  _vm.dialogTableVisible = true
                },
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { padding: "8px 10px" },
                  attrs: { size: "small", type: "danger" },
                },
                [_c("svg-icon", { attrs: { "icon-class": "bug" } })],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogTableVisible,
                title: "Error Log",
                width: "80%",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogTableVisible = $event
                },
              },
            },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.errorLogs, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: { label: "Message" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                [
                                  _c("span", { staticClass: "message-title" }, [
                                    _vm._v("Msg:"),
                                  ]),
                                  _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v(" " + _vm._s(row.err.message) + " "),
                                  ]),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "div",
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "message-title",
                                      staticStyle: { "padding-right": "10px" },
                                    },
                                    [_vm._v("Info: ")]
                                  ),
                                  _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.vm.$vnode.tag) +
                                        " error in " +
                                        _vm._s(row.info) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "div",
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "message-title",
                                      staticStyle: { "padding-right": "16px" },
                                    },
                                    [_vm._v("Url: ")]
                                  ),
                                  _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(" " + _vm._s(row.url) + " "),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3134886942
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Stack" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(" " + _vm._s(scope.row.err.stack) + " "),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2525805560
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }