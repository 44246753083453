<template>
  <div class="navbar">
    <el-menu class="el-menu-demo" mode="horizontal">
      <el-menu-item><logo v-if="showLogo" :collapse="isCollapse" :is-externo="true"/></el-menu-item>
      <div v-if="!isMobile()" class="center">
        <el-menu-item class="item-menu">
          <el-button class="filter-item" type="default">
            <router-link to="/usuario-externo/protocolo">Meus Protocolos</router-link>
          </el-button>
        </el-menu-item>
        <el-menu-item class="item-menu">
          <el-button class="filter-item" type="default">
            <router-link to="#">Assinaturas</router-link>
          </el-button>
        </el-menu-item>
        <el-menu-item class="item-menu">
          <el-button class="filter-item" type="default">
            <router-link to="#">Comunicações</router-link>
          </el-button>
        </el-menu-item>
      </div>
      <div class="right-menu">
        <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
          <span class="el-dropdown-link">
            <div v-if="user.avatar !== null">
              <el-image class="circle" :src="getFullImageSrc()" />
                <span v-if="!isMobile()" class="nome-usuario">{{ userInitials }}</span>
            </div>
            <div v-else class="circle" :style="{ backgroundColor: randomColor }">
              {{ userInitials }}
            </div>
             <span v-if="isMobile()" class="nome-usuario">{{ usuario }}</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/usuario-externo/perfil/">
              <el-dropdown-item>Perfil</el-dropdown-item>
            </router-link>
            <el-dropdown-item divided>
              <span style="display: block" @click="logout">Sair</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '../../../layout/components/Sidebar/Logo.vue'
export default {
  name: 'NavBar',
  components: {
    Logo
  },
  data() {
    var images = require.context('@/assets/', false, /\.png$/)
    return {
      title: 'celerdoc',
      logo: images(`./header.png`),
      isCollapse: false,
      showLogo: true,
      mobile: false,
      user: { info: null, avatar: '' },
      randomColor: this.getRandomColor()
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'device', 'usuario', 'name', 'avatar']),
    userInitials() {
      const names = this.user.name.split(' ')
      if (names.length >= 2) {
        const firstName = names[0]
        const lastName = names[names.length - 1]
        return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
      }
      return names[0].charAt(0).toUpperCase()
    }
  },
  created() {
    this.getUser()
  },
  beforeMount() {
    this.isMobile()
  },
  beforeDestroy() {
    this.isMobile()
  },
  methods: {
    getFullImageSrc() {
      return 'data:image/png;base64,' + this.user.avatar
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    getUser() {
      this.user = {
        info: this.userInfo,
        avatar: this.avatar,
        name: this.name
      }
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF'
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 60px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .center {
    position: relative;
    display: inline-flex;
    margin: auto;
    text-align: center;
    justify-content: center;
    background-color: transparent;
  }
  .el-menu-item:hover {
    background-color: transparent;
  }
  .item-menu {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: middle;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 0px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}

.nome-usuario {
  float: left;
  padding-left: 10px;
  padding-top: 8px;
}

.circle {
  float: left;
  margin-top: 14px;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  text-align: center;
  line-height: 33px;
  color: white;
  font-weight: bolder;
}

.circle-img {
  margin-top: 14px;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  text-align: center;
  line-height: 33px;
  color: white;
  font-weight: bolder;
}

.menu-ajuda {
  float: right;
  padding-top: 9px;
  width: 30%;
  padding-right: 10px;
  text-align: right;
  display: inline-block;
}
.menu-rodape {
  padding-top: 16px;
  display: flex;
  height: 56px;
}
.menu-perfil {
  float: left;
  display: inline-block;
  width: 70%;
  padding-left: 10px;
  cursor: pointer;
}
.icon-ajuda {
  margin-right: 7px !important;
}
</style>
