import Vue from 'vue'
import Router from 'vue-router'

/* Layout */
import Layout from '@/layout'
import Layout2 from '@/layout-secundario'

/* Router Modules */
import ajuda from './modules/ajuda'
import crudRouter from './modules/crud'
import meus from './modules/meus'
import userExterno from './modules/userExterno'

Vue.use(Router)

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export const constantRoutes = [

    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path*',
                component: () => import('@/views/redirect/index')
            }
        ]
    },
    {
        path: '/login',
        component: Layout2,
        redirect: '/login/index',
        hidden: true,
        children: [
            {
                path: 'index',
                component: () => import('@/views/login/index'),
                name: 'login',
                title: 'login'
            }
        ]
    },
    {
        path: '/cliente',
        component: Layout2,
        redirect: '/cliente/index',
        hidden: true,
        children: [
            {
                path: 'index',
                component: () => import('@/views/cliente/index'),
                name: 'cliente',
                title: 'cliente'
            }
        ]
    },
    {
        path: '/esqueceuSenha',
        component: Layout2,
        redirect: '/esqueceuSenha',
        hidden: true,
        children: [
            {
                path: '/esqueceuSenha',
                component: () => import('@/views/login/esqueceu-senha'),
                name: 'esqueceuSenha',
                title: 'esqueceuSenha'
            }
        ]
    },
    {
        path: '/ativar-usuario/:token',
        component: () => import('@/views/login/ativar-usuario'),
        hidden: true
    },
    {
        path: '/auth-redirect',
        component: () => import('@/views/login/auth-redirect'),
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/error-page/404'),
        hidden: true
    },
    {
        path: '/401',
        component: () => import('@/views/error-page/401'),
        hidden: true
    },
    {
        path: '',
        component: Layout,
        redirect: 'Dashboard',
        hidden: true,
        children: [
            {
                path: 'dashboard',
                component: () => import('@/views/dashboard-administrativo/index'),
                name: 'Dashboard',
                title: 'Dashboard',
                meta: { icon: 'search-alt', noCache: true }
            }
        ]
    },
    {
        path: '/consulta',
        component: Layout,
        redirect: 'pesquisa',
        children: [
            {
                path: 'pesquisa',
                component: () => import('@/views/dashboard/index'),
                name: 'Consulta',
                title: 'Consulta',
                meta: { icon: 'search-alt', noCache: true }
            }
        ]
    },
    {
        path: '/perfil',
        component: Layout,
        redirect: '/perfil/index',
        hidden: true,
        children: [
            {
                path: 'index',
                component: () => import('@/views/profile/index'),
                name: 'Perfil',
                title: 'Perfil',
                meta: { icon: 'user', noCache: true }
            }
        ]
    }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
    /** when your routing map is too long, you can split it into small modules **/
    crudRouter,
    meus,
    userExterno,
    ajuda,
    { path: '*', redirect: '/pesquisa', hidden: true }
]

const createRouter = () =>
    new Router({
        // mode: 'history', // require service support
        scrollBehavior: () => ({ y: 0 }),
        routes: constantRoutes
    })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
