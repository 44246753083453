var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: { id: "processo-anexos" },
    },
    [
      _vm.anexos.length <= 0
        ? _c("div", [_c("p", [_vm._v("Sem anexos")])])
        : _vm._e(),
      _c("br"),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.upload,
              expression: "upload",
            },
          ],
          staticClass: "tabs_row",
          attrs: { gutter: 20 },
        },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "grid-content" },
              [
                _vm.podeEditar()
                  ? _c(
                      "el-button",
                      {
                        staticClass: "filter-item",
                        attrs: { size: "medium", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.showUpload()
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "plus-square" },
                        }),
                        _vm._v("   Adicionar Anexo "),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.anexos, height: "500" },
            },
            [
              _c("el-table-column", {
                staticClass: "tabela-cabecalho-fixo",
                attrs: {
                  prop: "tx_nome",
                  label: "Nome",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "link-type",
                            on: {
                              click: function ($event) {
                                return _vm.showEdit(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(scope.$index + 1) +
                                ". " +
                                _vm._s(_vm._f("parseNull")(scope.row.tx_nome)) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                staticClass: "tabela-cabecalho-fixo",
                attrs: {
                  prop: "tipo",
                  label: "Tipo",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("parseValues")(
                                scope.row.tipo,
                                "pecaCategoria"
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                staticClass: "tabela-cabecalho-fixo",
                attrs: {
                  prop: "dt_criacao",
                  label: "Data de Criação",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatDate")(
                                scope.row.dt_criacao,
                                "datetime"
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                staticClass: "tabela-cabecalho-fixo",
                attrs: { label: "Ações" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.podeEditar()
                          ? _c(
                              "div",
                              { staticClass: "action-icons" },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [_vm._v("Visualizar anexo")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "icon-container" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "search" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.fileClick(scope.id)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [_vm._v("Excluir anexo")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "icon-container" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "trash" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deletarAnexo(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("pecaUpload", {
        ref: "pecaUpload",
        attrs: { tipo: "anexo", processo: _vm.processo },
        on: { addItem: _vm.handlePecaAdd, updateItem: _vm.handlePecaUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }