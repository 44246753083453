var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showDetail
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading",
            },
          ],
          staticClass: "processoDetail",
        },
        [
          _vm._v(" ESTOU AQUI "),
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "space-between" } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.processo.tx_identificacao)),
                ]),
              ]),
              _c("el-col", { staticClass: "card-title", attrs: { span: 18 } }, [
                _vm._v(" " + _vm._s(_vm.processo.nome_tipo_processo) + " "),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", { staticClass: "small-info", attrs: { span: 18 } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.processo.bl_eletronico
                        ? "Eletrônico"
                        : "Não eletrônico"
                    ) +
                    " - " +
                    _vm._s(_vm._f("formatStatus")(_vm.processo.tx_status)) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c("el-row", [_c("p", [_vm._v(_vm._s(_vm.processo.tx_descricao))])]),
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "space-between" } },
            [
              _c("el-col", { attrs: { span: 18 } }, [
                _vm._v(
                  " Localização atual: " +
                    _vm._s(_vm.processo.nome_unidade_funcional_atual) +
                    " "
                ),
              ]),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _vm._v(" " + _vm._s(_vm.processo.nome_usuario_atual) + " "),
                  _c("svg-icon", {
                    attrs: {
                      "icon-class": "user",
                      "class-name": "card-panel-icon",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-row", [
            _vm._v(" Situação: " + _vm._s(_vm.processo.nome_fase) + " "),
          ]),
          _c(
            "el-row",
            [
              _c(
                "el-tabs",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tabLoading,
                      expression: "tabLoading",
                    },
                  ],
                  attrs: { type: "border-card" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "pecas", label: "Peças" } },
                    [
                      _vm.pecas.length <= 0
                        ? _c("el-alert", {
                            attrs: {
                              title: "Sem peças",
                              type: "warning",
                              effect: "dark",
                            },
                          })
                        : _vm._e(),
                      _vm._l(_vm.pecas, function (item, index) {
                        return _c(
                          "el-row",
                          {
                            key: item.id,
                            staticClass: "tabs_row",
                            attrs: { gutter: 20 },
                          },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("div", { staticClass: "grid-content" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(index + 1) +
                                    ". " +
                                    _vm._s(_vm._f("parseNull")(item.tx_nome))
                                ),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 4 } }, [
                              _c("div", { staticClass: "grid-content" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("parseValues")(
                                        item.tipo,
                                        "pecaCategoria"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 4 } }, [
                              _c("div", { staticClass: "grid-content" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        item.dt_criacao,
                                        "datetime"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      content: "Baixar peça",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "icon-item click-item" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "peca-show" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.fileClick(item.id)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "vinculados", label: "Vinculados" } },
                    [
                      _vm.vinculados.length <= 0
                        ? _c("el-alert", {
                            attrs: {
                              title: "Sem vinculados",
                              type: "warning",
                              effect: "dark",
                            },
                          })
                        : _vm._e(),
                      _vm._l(_vm.vinculados, function (item) {
                        return _c(
                          "el-row",
                          {
                            key: item.id,
                            staticClass: "tabs_row",
                            attrs: { gutter: 20 },
                          },
                          [
                            _c("el-col", { attrs: { span: 14 } }, [
                              _c("div", { staticClass: "grid-content" }, [
                                _vm._v(" " + _vm._s(item.name)),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "grid-content" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        item.dt_inicio,
                                        "datetime"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 2 } },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      content: "Ver protocolo",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "icon-item click-item" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "processos" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.loadProtocolo(
                                                item.id_processo_filho
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "interessados", label: "Interessados" } },
                    [
                      _vm.interessados.length <= 0
                        ? _c("el-alert", {
                            attrs: {
                              title: "Sem interessados",
                              type: "warning",
                              effect: "dark",
                            },
                          })
                        : _vm._e(),
                      _vm._l(_vm.interessados, function (item) {
                        return _c(
                          "el-row",
                          {
                            key: item.id,
                            staticClass: "tabs_row",
                            attrs: { gutter: 20 },
                          },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "grid-content" }, [
                                _vm._v(" " + _vm._s(item.name)),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 4 } }, [
                              _c("div", { staticClass: "grid-content" }, [
                                _vm._v(" " + _vm._s(item.number) + " "),
                              ]),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "notas", label: "Notas" } },
                    [
                      _vm.notas.length <= 0
                        ? _c("el-alert", {
                            attrs: {
                              title: "Sem notas",
                              type: "warning",
                              effect: "dark",
                            },
                          })
                        : _vm._e(),
                      _vm._l(_vm.notas, function (item, index) {
                        return _c(
                          "el-row",
                          {
                            key: item.id,
                            staticClass: "tabs_row",
                            attrs: { gutter: 20 },
                          },
                          [
                            _c("el-col", { attrs: { span: 2 } }, [
                              _c("div", { staticClass: "grid-content" }, [
                                _vm._v(" " + _vm._s(index + 1)),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "grid-content" }, [
                                _vm._v(" " + _vm._s(item.name)),
                              ]),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "historico", label: "Movimentação" } },
                    [
                      _vm.historico.length <= 0
                        ? _c("el-alert", {
                            attrs: {
                              title: "Sem Movimentação",
                              type: "warning",
                              effect: "dark",
                            },
                          })
                        : _vm._e(),
                      _vm._l(_vm.historico, function (item) {
                        return _c(
                          "el-row",
                          {
                            key: item.id,
                            staticClass: "tabs_row",
                            attrs: { gutter: 20 },
                          },
                          [
                            _c("el-col", { attrs: { span: 3 } }, [
                              _c("div", { staticClass: "grid-content" }, [
                                _c(
                                  "div",
                                  { staticClass: "icon-item" },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": item.tx_ocorrencia,
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("codeFilter")(
                                            item.tx_ocorrencia
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 21 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content history_block" },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "grid-content" },
                                          [
                                            _vm._v(
                                              " Usuário: " +
                                                _vm._s(item.usuario) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "grid-content" },
                                          [
                                            _vm._v(
                                              " Data: " +
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    item.dt_ocorrencia,
                                                    "datetime"
                                                  )
                                                )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "grid-content" },
                                          [
                                            _vm._v(
                                              " Host: " + _vm._s(item.tx_host)
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "grid-content" },
                                          [_vm._v(" IP: " + _vm._s(item.tx_ip))]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  item.tx_ocorrencia === "CR"
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grid-content",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Fase Origem: " +
                                                          _vm._s(item.fase) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grid-content",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Fase Origem: " +
                                                          _vm._s(item.fase) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                  item.tx_ocorrencia !== "SG" &&
                                  item.tx_ocorrencia !== "VS" &&
                                  item.tx_ocorrencia !== "CR"
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grid-content",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Usuário origem: " +
                                                          _vm._s(
                                                            item.usuario_origem
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grid-content",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Usuário destino: " +
                                                          _vm._s(
                                                            item.usuario_destino
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grid-content",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Unidade funcional: " +
                                                          _vm._s(
                                                            item.unidade_funcional
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grid-content",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Unidade funcional destino: " +
                                                          _vm._s(
                                                            item.unidade_funcional_destino
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grid-content",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Fase Origem: " +
                                                          _vm._s(item.fase) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grid-content",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Fase destino: " +
                                                          _vm._s(
                                                            item.fase_destino
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }