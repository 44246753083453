import axios from 'axios'
import { MessageBox } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import qs from 'qs'
import router from '@/router'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000, // request timeout
  paramsSerializer: function(params) {
    for (var x in params) {
      params[x] = (typeof params[x] === 'boolean') ? (params[x] ? 1 : 0) : params[x]
    }
    return qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (!res.status && res.message === 'not_authorized') {
      MessageBox.confirm('Sessão expirada', 'Confirmar logout', {
        confirmButtonText: 'Relogar',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      })
    } else {
      return res
    }
  },
  error => {
    if (typeof error.response === 'undefined') {
      MessageBox.confirm('Erro geral', 'Recarregar página', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(() => {
        location.reload()
      })
    } else if (error.response.data.message === 'not_authorized' && router.currentRoute.path !== '/') {
      MessageBox.confirm('Sessão expirada', 'Confirmar logout', {
        confirmButtonText: 'Relogar',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      })
    } else {
      return error.response.data
    }
  }
)

export default service
