<template>
  <div class="app-wrapper">
    <navbar />
    <div>
      <app-content />
    </div>
  </div>
</template>

<script>
import { Navbar, AppContent } from '@/views/layout-externo/components'
export default {
  name: 'AppLayout',
  components: {
    Navbar,
    AppContent
  }
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
