/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout'

const tableRouter = {
    path: '/meus',
    component: Layout,
    redirect: '/meus/criados',
    name: 'Meus',
    title: 'Meus Processos',
    meta: {
        icon: 'person-rolodex'

    },
    children: [
        {
            path: 'criados',
            component: () => import('@/views/meus/criados'),
            name: 'Criados',
            title: 'Criados'
        },
        {
            path: 'encaminhados',
            component: () => import('@/views/meus/encaminhados'),
            name: 'Encaminhados',
            title: 'Encaminhados'
        },
        {
            path: 'sou_interessado',
            component: () => import('@/views/meus/sou_interessado'),
            name: 'SouInteressado',
            title: 'Sou Interessado'
        },
        {
            path: 'rastreados',
            component: () => import('@/views/meus/rastreados'),
            name: 'Rastreados',
            title: 'Rastreados'
        }
    ]
}
export default tableRouter
