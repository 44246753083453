var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "setores-container" },
    _vm._l(_vm.setoresVisiveis, function (setor, index) {
      return _c("div", { key: index, class: _vm.getSetaClass(index) }, [
        _c("h5", [_vm._v(_vm._s(setor.unidade_funcional_destino_sigla))]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }