var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "header",
        { staticClass: "logo", staticStyle: { height: "73.05px" } },
        [
          _c("div", { staticClass: "logo1" }, [
            _c("a", { attrs: { href: "/" } }, [
              _c("img", { attrs: { src: `${_vm.logo}` } }),
            ]),
            _c(
              "div",
              { staticClass: "logo_2" },
              [
                _c("p", [_vm._v("Não tem uma conta?")]),
                _c("el-button", { attrs: { id: "buttonCadastrar" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "a-titulo",
                      on: { click: _vm.cadastrarCliente },
                    },
                    [_vm._v("Cadastre-se")]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("app-content"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }