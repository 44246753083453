<template>
  <el-dialog :title="title" :modal="false" :visible.sync="uploadVisible" width="60%">
    <el-form
      ref="peca-upload-form"
      :model="temp"
      :rules="rules"
      label-position="left"
      label-width="170px"
      style="margin-left: 50px; margin-right: 50px">
      <el-form-item :label="'Tipo de ' + (tipo === 'peca' ? 'peça' : 'anexo')" prop="id_tipo_peca">
        <el-select
          v-model="temp.id_tipo_peca"
          style="width: 80%"
          filterable
          remote
          :remote-method="(data) => loadTipos(data)"
          :loading="loading"
          placeholder="Digite para buscar"
          @change="handleTipoPeca">
          <el-option key="" label="Vazio" :value="null" />
          <el-option
            v-for="item in tipoPecaList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :autonum="item.bl_autonum" />
        </el-select>
        <div v-if="filterFormError('id_tipo_peca')" class="el-form-item__error">
          {{ showMsgError('id_tipo_peca') }}
        </div>
      </el-form-item>

      <el-form-item v-if="showNumero" label="Número da peça">
        <el-input v-model="temp.nr_numero" style="width: 80%" />
        <div v-if="filterFormError('nr_numero')" class="el-form-item__error">
          {{ showMsgError('nr_numero') }}
        </div>
      </el-form-item>

      <el-form-item label="Arquivo">
        <el-upload
          ref="uploadField"
          action=""
          :accept="filesAccepted"
          :http-request="addFile"
          :multiple="false"
          :limit="1"
          :file-list="fileList">
          <el-button slot="trigger" size="small" type="primary">Escolher arquivo</el-button>
        </el-upload>
        <div v-if="filterFormError('file')" class="el-form-item__error">
          {{ showMsgError('file') }}
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="uploadVisible = false">Cancelar</el-button>
      <el-button type="primary" :disabled="clicked" @click="save()">Confirmar</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addPeca } from '@/api/processo-peca'
import { find } from '@/api/tipo-peca'

import { createRule } from '@/utils'

export default {
  name: 'PecaUpload',
  props: {
    processo: {
      type: Object,
      default() {
        return {}
      }
    },
    tipo: {
      type: String,
      default() {
        return 'peca'
      }
    }
  },
  data() {
    return {
      filesAccepted: this.tipo === 'peca' ? '.pdf' : null,
      editPeca: false,
      loading: false,
      uploadVisible: false,
      showNumero: false,
      temp: {
        id: '',
        id_processo: undefined,
        id_tipo_peca: null,
        id_unidade_funcional: null,
        nr_numero: null
      },
      params: new FormData(),
      tipoPecaList: [],
      custom_errors: {},
      fileList: [],
      title: '',
      clicked: false,
      rules: {
        id_tipo_peca: createRule([{ label: 'Tipo de peça', type: 'required' }])
      }
    }
  },
  mounted() {
    this.loadTipos('')
  },
  methods: {
    handleTipoPeca(value) {
      for (var x in this.tipoPecaList) {
        if (this.tipoPecaList[x].value === value) {
          this.showNumero = !this.tipoPecaList[x].autonum
        }
      }
    },
    handleShow(peca = null) {
      this.title =
        'Protocolo ' +
        this.processo.tx_identificacao +
        (peca ? peca.tx_nome : this.tipo === 'peca' ? ' - Nova Peça' : ' - Novo Anexo')
      this.uploadVisible = true
      this.resetTemp()
      if (peca) {
        this.editPeca = true
        this.temp.id = peca.id
        this.temp.id_tipo_peca = peca.id_tipo_peca
        this.temp.nr_numero = peca.id_processo
        this.fileList.push({ name: peca.tx_nome })
      }
    },
    filterFormError(field) {
      if (this.custom_errors[field]) {
        return this.custom_errors[field].show
      }
      return false
    },
    showMsgError(field) {
      if (this.custom_errors[field]) {
        return this.custom_errors[field].msg
      }
    },
    loadTipos(query) {
      this.tipoPecaList = []
      this.loading = true
      if (query === 'Vazio') {
        query = null
      }

      const filters = {
        name: query,
        id_tipo_processo: this.processo.id_tipo_processo,
        id_unidade_funcional_atual: this.processo.id_unidade_funcional_atual,
        tx_categoria: this.tipo === 'peca' ? 'P' : 'M'
      }
      find(filters).then((response) => {
        if (response.status) {
          const data = response.data
          for (var x in data) {
            this.tipoPecaList.push({
              label: data[x].name,
              value: data[x].id,
              autonum: data[x].bl_autonum
            })
          }
        }
        this.loading = false
      })
    },
    addFile(upload) {
      if (this.params.has('file')) {
        this.params.set('file', upload.file, upload.file.name)
      } else {
        this.params.append('file', upload.file, upload.file.name)
      }
    },
    resetTemp() {
      this.temp = {
        id: '',
        id_processo: null,
        id_tipo_peca: null,
        id_unidade_funcional: 0,
        nr_numero: null
      }
      this.editPeca = false
      this.fileList = []
      this.custom_errors = {}
      this.params = new FormData()
      if (this.$refs.uploadField) {
        this.$refs.uploadField.clearFiles()
      }
    },
    save() {
      this.$refs['peca-upload-form'].validate((valid) => {
        if (valid) {
          this.clicked = true
          this.temp.id_processo = this.processo.id
          this.temp.id_unidade_funcional = this.processo.id_unidade_funcional_atual
          this.temp.tx_tipo = this.tipo === 'peca' ? 'P' : 'M'

          for (var x in this.temp) {
            if (this.params.has(x)) {
              this.params.set(x, this.temp[x])
            } else {
              this.params.append(x, this.temp[x])
            }
          }

          addPeca(this.params).then((response) => {
            if (response.status) {
              this.$notify({
                title: (this.tipo === 'peca' ? 'Peça' : 'Anexo') + ' adicionada',
                message: 'Sucesso!',
                type: 'success',
                duration: 2000
              })
              if (this.editPeca) {
                this.$emit('updateItem', response.data)
              } else {
                this.$emit('addItem', response.data)
              }
              this.resetTemp()
              this.uploadVisible = false
              this.clicked = false
            } else {
              this.$notify({
                title: 'Adicionar Peça',
                message: 'Erro ao fazer upload da peça!',
                type: 'danger',
                duration: 2000
              })
              this.clicked = false
            }
          })
        }
      })
    }
  }
}
</script>
