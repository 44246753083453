var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c(
        "el-menu",
        { staticClass: "el-menu-demo", attrs: { mode: "horizontal" } },
        [
          _c(
            "el-menu-item",
            [
              _vm.showLogo
                ? _c("logo", {
                    attrs: { collapse: _vm.isCollapse, "is-externo": true },
                  })
                : _vm._e(),
            ],
            1
          ),
          !_vm.isMobile()
            ? _c(
                "div",
                { staticClass: "center" },
                [
                  _c(
                    "el-menu-item",
                    { staticClass: "item-menu" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { type: "default" },
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/usuario-externo/protocolo" } },
                            [_vm._v("Meus Protocolos")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-menu-item",
                    { staticClass: "item-menu" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { type: "default" },
                        },
                        [
                          _c("router-link", { attrs: { to: "#" } }, [
                            _vm._v("Assinaturas"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-menu-item",
                    { staticClass: "item-menu" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { type: "default" },
                        },
                        [
                          _c("router-link", { attrs: { to: "#" } }, [
                            _vm._v("Comunicações"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "right-menu" },
            [
              _c(
                "el-dropdown",
                {
                  staticClass: "avatar-container right-menu-item hover-effect",
                  attrs: { trigger: "click" },
                },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _vm.user.avatar !== null
                      ? _c(
                          "div",
                          [
                            _c("el-image", {
                              staticClass: "circle",
                              attrs: { src: _vm.getFullImageSrc() },
                            }),
                            !_vm.isMobile()
                              ? _c("span", { staticClass: "nome-usuario" }, [
                                  _vm._v(_vm._s(_vm.userInitials)),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "circle",
                            style: { backgroundColor: _vm.randomColor },
                          },
                          [_vm._v(" " + _vm._s(_vm.userInitials) + " ")]
                        ),
                    _vm.isMobile()
                      ? _c("span", { staticClass: "nome-usuario" }, [
                          _vm._v(_vm._s(_vm.usuario)),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/usuario-externo/perfil/" } },
                        [_c("el-dropdown-item", [_vm._v("Perfil")])],
                        1
                      ),
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: { display: "block" },
                            on: { click: _vm.logout },
                          },
                          [_vm._v("Sair")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }