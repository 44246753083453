import Vue from 'vue'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/pt-br'
import './styles/element-variables.scss'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import { helpers } from './utils'
import * as filters from './filters' // global filters
import VueTheMask from 'vue-the-mask'

Vue.use(Element, {
  size: 'medium', // set element-ui default size
  locale: locale
})
Vue.prototype.helper = helpers
Vue.use(VueTheMask)
Vue.use(require('vue-moment'))
// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
