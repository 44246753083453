var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { display: "flex", "padding-top": "16px" } },
        [
          _c(
            "div",
            { class: { "has-logo": _vm.showLogo } },
            [
              !_vm.isCollapse
                ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
                : _vm._e(),
            ],
            1
          ),
          _c("hamburger", {
            staticClass: "hamburger-container",
            attrs: {
              id: "hamburger-container",
              "is-active": _vm.sidebar.opened,
            },
            on: { toggleClick: _vm.toggleSideBar },
          }),
        ],
        1
      ),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": "#fff",
                "text-color": "#bfcbd9",
                "unique-opened": false,
                "active-text-color": "#409eff",
                "collapse-transition": false,
                mode: "vertical",
              },
            },
            [
              _c("search", {
                staticClass: "right-menu-item",
                attrs: { id: "header-search" },
              }),
              !_vm.isCollapse
                ? _c(
                    "div",
                    {
                      staticClass: "align-item-btn",
                      attrs: { collapse: _vm.isCollapse },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn-primary",
                          staticStyle: { "font-size": "14px" },
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: {
                            click: function ($event) {
                              return _vm.abrirNovoProtocolo()
                            },
                          },
                        },
                        [_vm._v("Novo Protocolo")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.outrosObjetos, function (route) {
                return _c("sidebar-item", {
                  key: route.path,
                  attrs: { item: route, "base-path": route.path },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "menu-rodape" }, [
        _c(
          "div",
          { staticClass: "menu-perfil" },
          [
            _c(
              "el-dropdown",
              {
                staticClass: "avatar-container right-menu-item hover-effect",
                attrs: { trigger: "click" },
              },
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _vm.user.avatar !== null
                    ? _c(
                        "div",
                        [
                          _c("el-image", {
                            staticClass: "circle",
                            attrs: { src: _vm.getFullImageSrc() },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "circle",
                          style: { backgroundColor: _vm.randomColor },
                        },
                        [_vm._v(" " + _vm._s(_vm.userInitials) + " ")]
                      ),
                  !_vm.isCollapse
                    ? _c("span", { staticClass: "nome-usuario" }, [
                        _vm._v(_vm._s(_vm.usuario)),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/perfil/index" } },
                      [_c("el-dropdown-item", [_vm._v("Perfil")])],
                      1
                    ),
                    _c("el-dropdown-item", { attrs: { divided: "" } }, [
                      _c(
                        "span",
                        {
                          staticStyle: { display: "block" },
                          on: { click: _vm.logout },
                        },
                        [_vm._v("Sair")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "menu-ajuda" },
          [
            _c(
              "el-dropdown",
              { staticClass: "ajuda", attrs: { trigger: "click" } },
              [
                _c(
                  "div",
                  { staticStyle: { cursor: "pointer" } },
                  [
                    _c("svg-icon", {
                      staticClass: "icon-ajuda",
                      attrs: { "icon-class": "question-circle" },
                    }),
                    !_vm.isCollapse ? _c("span", [_vm._v("Ajuda")]) : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  _vm._l(_vm.objetosAjuda, function (obj) {
                    return _c(
                      "router-link",
                      { key: obj.path, attrs: { to: "/ajuda/" + obj.path } },
                      [_c("el-dropdown-item", [_vm._v(_vm._s(obj.name))])],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("processos", {
        ref: "processos",
        attrs: { "before-load-view": false, filters: _vm.filtros, btn: false },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }