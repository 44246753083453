<template>
  <div id="processo-anexos" v-loading="loading">
    <div v-if="anexos.length <= 0">
      <p>Sem anexos</p>
    </div>
    <br />
    <el-row v-show="upload" class="tabs_row" :gutter="20">
      <el-col :span="24">
        <div class="grid-content">
          <el-button v-if="podeEditar()" size="medium" class="filter-item" type="primary" @click="showUpload()">
            <svg-icon icon-class="plus-square" />
            &nbsp; Adicionar Anexo
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-table :data="anexos" height="500" style="width: 100%">
        <el-table-column prop="tx_nome" label="Nome" class="tabela-cabecalho-fixo" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="link-type" @click="showEdit(scope.row)">
              {{ scope.$index + 1 }}. {{ scope.row.tx_nome | parseNull }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="tipo" label="Tipo" class="tabela-cabecalho-fixo" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.tipo | parseValues('pecaCategoria') }}
          </template>
        </el-table-column>
        <el-table-column prop="dt_criacao" label="Data de Criação" class="tabela-cabecalho-fixo" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.dt_criacao | formatDate('datetime') }}
          </template>
        </el-table-column>
        <el-table-column label="Ações" class="tabela-cabecalho-fixo">
          <template slot-scope="scope">
            <div v-if="podeEditar()" class="action-icons">
              <el-tooltip placement="top-start">
                <div slot="content">Visualizar anexo</div>
                <div class="icon-container">
                  <svg-icon icon-class="search" @click="fileClick(scope.id)" />
                </div>
              </el-tooltip>
              <el-tooltip placement="top-start">
                <div slot="content">Excluir anexo</div>
                <div class="icon-container">
                  <svg-icon icon-class="trash" @click="deletarAnexo(scope.row, scope.$index)" />
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <pecaUpload
      ref="pecaUpload"
      tipo="anexo"
      :processo="processo"
      @addItem="handlePecaAdd"
      @updateItem="handlePecaUpdate" />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MessageBox } from 'element-ui'

import { getPecas, deletarPeca } from '@/api/processo-peca'
import pecaUpload from './PecaUpload'
import { getURL } from '@/utils'

export default {
  name: 'Acoes',
  components: { pecaUpload },
  filters: {
    formatDate(date, type) {
      if (type === 'date') {
        return date ? Vue.moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY') : ''
      } else {
        return date ? Vue.moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY HH:mm:ss') : ''
      }
    },
    parseNull(value) {
      return value === 'null' ? '[ ]' : value
    }
  },
  props: {
    processo: {
      type: Object,
      default() {
        return {}
      }
    },
    upload: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      loading: false,
      anexos: []
    }
  },
  computed: {
    ...mapGetters(['user_id'])
  },
  methods: {
    podeEditar() {
      return this.processo.tx_status !== 'CL'
    },
    handlePecaAdd(item) {
      this.anexos.push(item)
    },
    handlePecaUpdate(item) {
      this.anexos.splice(this.anexos.indexOf(item), 1, item)
    },
    refresh() {
      this.loading = true
      getPecas({ id_processo: this.processo.id, tx_tipo: 'M' }).then((response) => {
        this.loading = false
        if (response.status) {
          this.anexos = response.data
        }
      })
    },
    deletarAnexo(item, index) {
      MessageBox.confirm('Deseja mesmo excluir o anexo?', 'Exclusão de anexo', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(() => {
        this.tabLoading = true
        deletarPeca(item.id).then((response) => {
          if (response.status) {
            this.anexos.splice(index, 1)
            this.$notify({
              title: 'Anexo excluido',
              message: 'Sucesso!',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Anexo',
              message: response.message,
              type: 'error',
              duration: 2000
            })
          }
          this.tabLoading = false
        })
      })
    },
    fileClick(id) {
      window.open(getURL('download/peca/' + this.user_id + '/' + id), '_blank')
    },
    showEdit(item) {
      this.$refs.pecaUpload.handleShow(item)
    },
    showUpload() {
      this.$refs.pecaUpload.handleShow()
    }
  }
}
</script>
<style lang="scss" scoped>
.tabela-cabecalho-fixo th {
  position: sticky;
  top: 0;
  background-color: #fff; /* Define a cor de fundo desejada */
  z-index: 1; /* Certifique-se de que o cabeçalho fique na parte superior das células de dados */
}

.tabela-cabecalho-fixo th,
.tabela-cabecalho-fixo td {
  min-width: 100px; /* Define a largura mínima das colunas */
}
</style>
<style lang="scss">
.icon-container {
  width: 25px; /* Defina o tamanho desejado para os ícones */
  height: 25px;
  display: flex;
  justify-content: center; /* Alinha horizontalmente ao centro */
  align-items: center; /* Alinha verticalmente ao centro */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.action-icons {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 17px;
}
.icon-container svg {
  width: 100%;
  height: 100%;
}
#processo-anexos {
  .el-row {
    margin-bottom: 15px;
    &:last-child {
      border-bottom: none;
    }
  }
  .icon-item {
    text-align: center;
    float: left;
    font-size: 20px;
    color: #24292e;
  }

  .click-item {
    cursor: pointer;
  }
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  font-size: 16px;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-size: 16px;
}
</style>
