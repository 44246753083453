<template>
  <el-dialog title="Vinculado" :modal="false" :visible.sync="dialogVisible" width="60%">
    <processoDetail ref="basicDetail" />
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">
        Cancelar
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import processoDetail from '@/views/dashboard/basic/components/ProcessoDetails'

export default {
  name: 'ProcessoDetail',
  components: { processoDetail },
  data() {
    return {
      title: '',
      dialogVisible: false
    }
  },
  methods: {
    handleShow(id_processo) {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.basicDetail.handleShow(id_processo)
      })
    }

  }
}
</script>
