var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "executar-acao" },
    [
      _vm.showDialog
        ? _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                title: _vm.gerarTitulo(),
                visible: _vm.showDialog,
                width: "500px",
                top: "28px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showDialog = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "acaoForm",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.acaoModel,
                    "label-width": "215px",
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _vm.checarCampo("id_usuario")
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Funcionário de destino",
                                prop: "id_usuario",
                              },
                            },
                            [
                              _c("custom-list", {
                                attrs: {
                                  "field-key": "id_usuario",
                                  "field-value": _vm.acaoModel.id_usuario,
                                  "query-function": _vm.findUsuarios,
                                  filter: _vm.usuarioFiltro,
                                },
                                on: {
                                  "update:fieldValue": function ($event) {
                                    return _vm.$set(
                                      _vm.acaoModel,
                                      "id_usuario",
                                      $event
                                    )
                                  },
                                  "update:field-value": function ($event) {
                                    return _vm.$set(
                                      _vm.acaoModel,
                                      "id_usuario",
                                      $event
                                    )
                                  },
                                  "update:filter": function ($event) {
                                    _vm.usuarioFiltro = $event
                                  },
                                  "value:updated": _vm.handleModel,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checarCampo("id_unidade_funcional_destino")
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Unidade funcional destino",
                                prop: "id_unidade_funcional_destino",
                              },
                            },
                            [
                              _c("custom-list", {
                                attrs: {
                                  "field-key": "id_unidade_funcional_destino",
                                  "field-value":
                                    _vm.acaoModel.id_unidade_funcional_destino,
                                  "query-function": _vm.findUnidades,
                                  filter: _vm.unidadeFiltro,
                                  config: {
                                    disabled: _vm.unidadeDestinoDisabled,
                                  },
                                },
                                on: {
                                  "update:fieldValue": function ($event) {
                                    return _vm.$set(
                                      _vm.acaoModel,
                                      "id_unidade_funcional_destino",
                                      $event
                                    )
                                  },
                                  "update:field-value": function ($event) {
                                    return _vm.$set(
                                      _vm.acaoModel,
                                      "id_unidade_funcional_destino",
                                      $event
                                    )
                                  },
                                  "update:filter": function ($event) {
                                    _vm.unidadeFiltro = $event
                                  },
                                  "value:updated": _vm.handleModel,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checarCampo("id_processo_filho")
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Processo para juntar",
                                prop: "id_processo_filho",
                              },
                            },
                            [
                              _c("custom-list", {
                                attrs: {
                                  "field-key": "id_processo_filho",
                                  "field-value":
                                    _vm.acaoModel.id_processo_filho,
                                  "query-function": _vm.findProcessos,
                                  filter: _vm.processoFiltro,
                                },
                                on: {
                                  "update:fieldValue": function ($event) {
                                    return _vm.$set(
                                      _vm.acaoModel,
                                      "id_processo_filho",
                                      $event
                                    )
                                  },
                                  "update:field-value": function ($event) {
                                    return _vm.$set(
                                      _vm.acaoModel,
                                      "id_processo_filho",
                                      $event
                                    )
                                  },
                                  "update:filter": function ($event) {
                                    _vm.processoFiltro = $event
                                  },
                                  "value:updated": _vm.handleModel,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checarCampo("arquivar")
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "Arquivar" } },
                            [
                              _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v("Sim"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.checarCampo("desarquivar")
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "Desarquivar" } },
                            [
                              _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v("Sim"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.fazerAcao },
                    },
                    [_vm._v("Salvar")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }