var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        modal: false,
        visible: _vm.dialogVisible,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: { "margin-left": "50px", "margin-right": "50px" },
          attrs: {
            model: _vm.temp,
            "label-position": "left",
            "label-width": "170px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Texto" } },
            [
              _c("el-input", {
                staticStyle: { width: "80%" },
                attrs: { type: "textarea", rows: 2 },
                model: {
                  value: _vm.temp.tx_texto,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "tx_texto", $$v)
                  },
                  expression: "temp.tx_texto",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(" Cancelar ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v(" Confirmar ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }