<template>
  <span v-loading="loading" :style="[localConfig.disabled ? {'opacity':'0.8', 'pointer-events': 'none'} : {'opacity':'1'}]">
    <el-tooltip v-if="!selectVisible && localSelectedLabel" :disabled="!noDataFound" :content="noDataFound" effect="dark" placement="top">
      <el-button :style="'text-align: left; width: '+ (localConfig.buttonWidth || localConfig.manualWidth)" :class="listClass" @click="showSelect">
        {{ localSelectedLabel }}
      </el-button>
    </el-tooltip>

    <el-select
      v-else
      :ref="fieldKey"
      v-model="localSelectedValue"
      :disabled="localConfig.disabled"
      filterable
      remote
      :clearable="localConfig.clearable"
      :multiple="localConfig.multipleList"
      :multiple-limit="localConfig.multipleLimit"
      :placeholder="localConfig.placeholder"
      :allow-create="localConfig.allowCreate"
      popper-append-to-body
      :remote-method="query => remoteFind(query)"
      :style="'width: '+localConfig.manualWidth"
      :class="listClass"
      @change="handleChange"
      @keyup.enter.native="value => handleChange()"
      @visible-change="handleVisibleChange"
      @clear="handleClear"
      @blur="resetList"
    >
      <el-option
        v-for="item in getOptionsList()"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />

    </el-select>
  </span>
</template>

<script>
export default {
  name: 'CustomList',
  props: {
    fieldKey: {
      type: String,
      default() {
        return null
      }
    },
    fieldValue: {
      type: [Array, Number, String],
      default() {
        return null
      }
    },
    fieldLabel: {
      type: String,
      default() {
        return null
      }
    },
    list: {
      type: Array,
      default() {
        return []
      }
    },
    filter: {
      type: Object,
      default() {
        return null
      }
    },
    queryFunction: {
      type: [Boolean, Function],
      default() {
        return false
      }
    },
    config: {
      type: Object,
      default() {
        return null
      }
    },
    listClass: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      localConfig: {
        optionLabel: 'name',
        optionValue: 'value',
        allowCreate: false,
        manualWidth: '100%',
        buttonWidth: null,
        placeholder: '',
        disabled: false,
        multipleList: false,
        multipleLimit: 0,
        clearable: true
      },
      noDataFound: null,
      selectVisible: true,
      loading: false,
      localList: [],
      backupList: null,
      localFilter: {},
      showAddIcon: false,
      localSelectedValue: null,
      localSelectedLabel: null
    }
  },
  computed: {
    defaultValueSet: function() {
      return this.localSelectedValue && this.localSelectedValue > 0
    }
  },
  watch: {
    fieldValue: function(newValue) { // if this prop change in parent, we need to update here
      this.localSelectedValue = newValue
    },
    fieldLabel: function(newValue) { // if this prop change in parent, we need to update here
      this.localSelectedLabel = newValue
      if (this.localSelectedLabel && !(this.localConfig.multipleList)) {
        this.selectVisible = false
      }
    },
    list: function(newList) { // if this prop change in parent, we need to update here
      if (newList && newList.length > 0) {
        this.localList = []
        newList.forEach(e => {
          this.localList.push({
            label: e[this.localConfig.optionLabel] || e.label || e.name || '',
            value: e[this.localConfig.optionValue] || e.value || e.id || null,
            is_default: e.is_default
          })
        })
      }
    },
    config: function(newConfig) { // if this prop change in parent, we need to update here
      if (newConfig) {
        Object.assign(this.localConfig, newConfig)
      }
    },
    filter: function(newFilter, oldFilter) { // if this prop change in parent, we need to update here
      if (JSON.stringify(newFilter) !== JSON.stringify(oldFilter)) {
        Object.assign(this.localFilter, newFilter)
        this.remoteFind()
      }
    }
  },
  mounted() {
    if (this.config) {
      Object.assign(this.localConfig, this.config)
    }
    this.localSelectedValue = this.fieldValue
    this.localSelectedLabel = this.fieldLabel

    // initialize local list if we have something on the props
    if (this.list.length > 0) {
      this.list.forEach(e => {
        this.localList.push({
          label: e[this.localConfig.optionLabel] || e.label || e.name || '',
          value: e[this.localConfig.optionValue] || e.value || e.id || null,
          is_default: e.is_default
        })
      })

      // Select a default value if nothing is selected and we have a default flag set
      const filtered = this.localList.filter(c => c.is_default === 1)
      if (filtered.length > 0) {
        this.localSelectedValue = filtered[0].value
        this.handleChange()
      }
    } else if (!this.localSelectedLabel) { // if the local list stills empty and we don't have a default label
      // make the first query
      this.remoteFind()
    }
    // default don't show the dropdown if we have a label to show
    if (this.localSelectedLabel) {
      this.selectVisible = false
    }
  },
  methods: {
    getOptionsList() {
      return this.localList
    },
    remoteFind(query = null, forceSelect = false) {
      this.loading = true
      this.localFilter = {}
      this.noDataFound = null
      setTimeout(() => {
        if (this.queryFunction) {
          // querying
          if (query) {
            this.localFilter.text = query
          }

          this.localFilter.list = true
          this.queryFunction({ ...this.filter, ...this.localFilter }).then(response => {
            if (response.status) {
              this.localList = []
              response.data.forEach(item => {
                var is_default = item.hasOwnProperty('is_default') ? item.is_default : 0
                this.localList.push({
                  label: item[this.localConfig.optionLabel],
                  value: item[this.localConfig.optionValue],
                  is_default: is_default
                })

                // Select a default value if nothing is selected and we have a default flag set
                if (is_default && !this.defaultValueSet) {
                  this.localSelectedValue = item[this.localConfig.optionValue]
                  this.handleChange()
                }
              })
              if (forceSelect && this.localList.length > 0) {
                this.showSelect()
              }
              if (this.localList.length === 0) {
                this.noDataFound = 'Sem resultado'
              }
              this.$emit('list:updated', this.fieldKey, this.localList) // send back list update
            }
            this.loading = false
          })
        } else {
          // No query function, it's a local search
          if (query) {
            // save original array
            if (!this.backupList) {
              this.backupList = this.localList
            }
            // localList, backList have a structure of VALUE/LABEL/IS_DEFAULT
            // every query must check the original array, not the filtered one
            this.localList = this.backupList.filter(c => c.label.toLowerCase().indexOf(query) >= 0)

            if (this.localList.length === 0) {
              this.noDataFound = 'Sem resultado'
            }
          }

          if (forceSelect) {
            this.showSelect()
          }
          this.loading = false
        }
      }, 500)
    },
    handleClear() {
      this.localSelectedLabel = null
      this.localSelectedValue = null
      if (!this.queryFunction) {
        this.localList = this.backupList ? this.backupList : this.list
        this.$emit('list:updated', this.fieldKey, this.localList)
      }

      this.$emit('value:updated', {
        key: this.fieldKey,
        value: null,
        label: null
      })
    },
    resetList() {
      setTimeout(() => {
        if (this.backupList) this.localList = this.backupList
      }, 500)
    },
    showSelect() {
      // default values set so the first load of the list was not made yet
      if (this.localList.length === 0 && this.localSelectedLabel) {
        this.remoteFind(null, true)
      } else {
        this.selectVisible = true
        this.$nextTick(_ => {
          this.$refs[this.fieldKey].focus()
        })
      }
    },
    handleVisibleChange(visible) {
      if (!visible) {
        this.selectVisible = false
      }
      // recover the label if we have a valid selectedValue
      if (!this.localSelectedLabel && this.localSelectedValue) {
        this.localSelectedLabel = this.fieldLabel
      }
    },
    handleChange(value = null) {
      if ((value || value === 0) || (typeof value === 'object' && value !== null && value.length > 0)) {
        // Get label('s) for the selected('s) Values
        // const values_to_compare = this.multipleList ? [value] : value // that change here brought a bunch of errors
        const values_to_compare = this.localConfig.multipleList ? value : [value]
        const filteredList = this.localList.filter(function(e) {
          return values_to_compare.includes(e.value)
        })
        this.localSelectedLabel = filteredList.map(e => e.label).join(', ')
      }
      if (value !== null && value !== '') {
        // Emit a complete object
        this.$emit('value:updated', {
          key: this.fieldKey, // fieldValue
          value: value,
          label: this.localSelectedLabel
        })
      }
    }
  }
}
</script>
<style scoped>
  .el-button{
    white-space: break-spaces;
    padding: 10px 10px;
  }
</style>
